@import '../../../../../../components/assets/styles/variables'
@import '../../../../../../components/assets/styles/mixin'
.gallery
  display: flex
  @include r(970)
    overflow-x: scroll
    &::-webkit-scrollbar
      display: none
.detail__image
  border-radius: 12px
  width: 105px
  height: 105px
  object-fit: cover
  &__last
    position: absolute
    top: 0
    left: 0
    font-weight: 600
    font-size: 14px
    line-height: 20px
    text-align: center
    color: $white
    font-family: $PoppinsSemiBold
    background: rgba(0, 12, 47, .5)
    height: 100%
    width: 100%
    border-radius: 12px
    display: flex
    align-items: center
  &__area
    display: flex
    position: relative
    margin-right: 10px
    margin-bottom: 16px
    min-width: 105px
    &:last-child
      margin-right: 0