@import '../../assets/styles/_variables'
@import '../../assets/styles/_mixin'
div.wrapper
    width: 100%
    box-sizing: border-box
    position: relative
    border-radius: 16px
    input
        font-family: $Poppins
        font-style: normal
        font-weight: 400
        line-height: 24px
        width: 100%
        outline: none
        padding: 13px 19px
        border: 1.5px solid #666D82
        border-radius: 16px
        background-color: transparent
        box-sizing: border-box
        margin-bottom: 5px
        font-size: 16px
        color: #666D82
        &.isActiveInput
            color: #000C2F
            border: 1.5px solid #000C2F
        @include r(768)
            font-size: 16px
        &:invalid
            box-shadow: none
        &.error
            border: 1.5px solid #D1291B!important
            &:hover
                border: 1.5px solid #D1291B!important
        &:hover
            border: 1.5px solid #000C2F
    textarea
        font-family: $Poppins
        width: 100%
        outline: none
        padding: 13px 24px
        border: 1.5px solid #666D82
        border-radius: 16px
        background-color: transparent
        box-sizing: border-box
        margin-bottom: 5px
        min-height: 98px
        resize: none
        touch-action: manipulation
        font-size: 16px
        &.isActiveInput
            color: #000C2F
            border: 1.5px solid #000C2F
        &:invalid
            box-shadow: none
        &.error
            border: 1.5px solid #D1291B!important
.label
    padding: 0 8px
    background-color: $white
    position: absolute
    top: -8px
    left: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    color: #666D82
    z-index: 10
    &.error
        color: #D1291B
.password
    width: 100%
    input
        padding-right: 40px
        touch-action: manipulation
    &__image
        width: 24px
        height: 24px
        position: absolute
        top: 15px
        right: 13px
        // transform: translateY(-50%)
        &:hover
            cursor: pointer
.message
    text-align: right
    color: #D1291B !important
    display: flex
    justify-content: flex-end
    font-size: 12px
    line-height: 16px
    position: relative
    left: 0
    //left: 24px
    //min-height: 1rem
    //width: calc(100% - 24px)
    margin-bottom: 15px
.radio
    display: none
    &:checked
        & + .radio__label
    //TODO: some active action!!!
    &__wrapper
        display: block
    &__label
        &:hover
            cursor: pointer
.code
    border: none
    box-sizing: border-box
    border-radius: 16px
    width: 100%
    font-size: 16px
    line-height: 24px
    padding: 13px 19px
    font-family: $Poppins
    position: relative
    background: none
    height: 100%
    color: #666D82
    margin-left: 5px
    &:invalid
        box-shadow: none
        &:focus
            border: none
    &__container
        width: 100%
        outline: none
        border: 1.5px solid #666D82
        border-radius: 16px
        background-color: $white
        box-sizing: border-box
        display: flex
        position: relative
        height: 50px
        margin-bottom: 17px
        &:hover
            border: 1.5px solid #000C2F
    &__containerError
        width: 100%
        outline: none
        border: 1.5px solid #D1291B!important
        border-radius: 16px
        background-color: $white
        box-sizing: border-box
        margin-bottom: 5px
        display: flex
        position: relative
        height: 50px
    &__wrapper
        height: 100%
        width: 147px
        position: relative
        box-sizing: border-box
    &__placeholder
        position: absolute
        box-sizing: border-box
        border-radius: 16px
        height: 100%
        width: 100%
        font-size: 16px
        line-height: 21px
        padding: 13px 19px
        font-family: $Poppins
        color: $darkgrey500
        margin-left: 5px
        pointer-events: none
        font-weight: 400
        i
            color: transparent
            opacity: 0
            visibility: hidden
.payment
    &__wrapper
        width: 100%
        position: relative
        box-sizing: border-box
        input
            margin-bottom: 0
    &__placeholder
        position: absolute
        box-sizing: border-box
        border-radius: 16px
        //height: 100%
        width: 100%
        font-size: 16px
        line-height: 24px
        height: 24px
        padding: 15px 24px
        font-family: $Poppins
        color: $dark20
        pointer-events: none
        i
            color: transparent
            opacity: 0
            visibility: hidden
// EXPIRE_INPUT_CONTROL
.expire
    width: 100%
    outline: none
    padding-left: 24px
    padding-right: 24px
    height: 52px
    border: 1.5px solid #666D82
    border-radius: 16px
    box-sizing: border-box
    margin-bottom: 5px
    font-size: 16px
    @include r(768)
    &:invalid
        box-shadow: none
    &__wrapper
        position: relative
        width: 100%
.mask
    position: absolute
    top: 2px
    left: 0
    border: 2px solid transparent
    z-index: -1
textarea
    font-size: 16px
input
    font-size: 16px
.price
    width: 100%
    border-radius: 16px
    position: relative
    &__label
        color: #666D82
        position: absolute
        left: 16px
        top: -7px
        background: #FFFFFF
        padding-left: 4px
        padding-right: 4px
        font-family: $Poppins
        font-size: 12px
    &__input
        border: 1.5px solid #666D82
        border-radius: 16px
        width: 100%
        height: 52px
        padding: 11px 24px
        &.active
            border: 1.5px solid $darkGrey900
        &::placeholder
            color: #666D82
            font-family: $Poppins
        &:hover
            border: 1.5px solid #000C2F
.isActiveLabel
    color: #000C2F
div input.isActiveInput
    color: #000C2F
    border: 1.5px solid #000C2F
.isActiveCode
    border: 1.5px solid #000C2F
.label__filled
    color: $darkGrey900
.info
    position: absolute
    right: 10px
    top: 8px
    width: 32px
    height: 32px
    display: flex
    justify-content: center
    align-items: center
    &:hover
        cursor: pointer
        .infoPopup
            display: block
            @include r(768)
                display: none
.infoPopup
    position: relative
    color: #000C2F
    font-size: 14px
    line-height: 20px
    padding: 16px
    border-radius: 24px
    position: absolute
    background: #FFFFFF
    top: -94px
    right: -28px
    box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08)
    font-family: $Poppins
    font-size: 14px
    color: $darkGrey900
    width: 418px
    display: none
    &:after
        content: ''
        position: absolute
        bottom: -6px
        right: 34px
        width: 0
        height: 0
        border-style: solid
        border-width: 7px 10px 0 10px
        border-color: #FFFFFF transparent transparent transparent
.depositError
    font-size: 12px
    font-family: $Poppins
    color: #D1291B
    position: relative
    top: 4px
.price__error
    border: 1px solid #D1291B!important
.label__error
    color: #D1291B
.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 5, 18, 0.3)
    z-index: 21
    display: block
.mobileInfo
    position: fixed
    bottom: 0
    left: 0
    right: 0
    border-top-left-radius: 16px
    border-top-right-radius: 16px
    background: #FFFFFF
    height: 230px
    z-index: 1000
    padding-left: 16px
    padding-right: 16px
    padding-top: 24px
.mobileInfoText
    color: #666D82
    font-family: $Poppins
    font-size: 16px
    line-height: 28px
.mobileIcon
    display: block
    margin-left: auto
    margin-right: auto
    margin-bottom: 24px


