
@import '../assets/styles/_variables'
@import '../assets/styles/_mixin'
.innerInputWrapper
    position: absolute
    top: 4px
    border-radius: 8px
    padding-left: 20px
    font-size: 16px
    font-family: $Poppins
.innerInput
    padding: 0
    top: 4px
    width: 23px
    height: 46px
    border-radius: 8px
    border: 0
.inputFilled
    width: 20px
.innerMinutesInput
    padding: 0
    position: absolute
    color: #CCCED5
    left: 48px
    top: 0
    width: 26px
    height: 46px
    border-radius: 8px
    border: 0
    background: #ffffff

.wrapper 
    display: flex
    width: 228px
    justify-content: space-between
    align-items: center
    margin-bottom: 12px
    margin-left: 8px
    @include r(550)
        width: 170px
        margin-left: 0
.blockWrapper
    display: flex
    flex-direction: column
    align-items: center
    font-size: 14px
.arrow   
    width: 30px
    height: 25px
    background: transparent
    background-position: center
    background-repeat: no-repeat
    border: 0
.arrowUp
    background-image: url('./arrow-up.svg')
.arrowDown
    background-image: url('./arrow-down.svg')
.hoursInput 
    width: 40px
    padding-left: 11px
    border: 0
    background: transparent
    color: $darkGrey900
.amPmButton
    width: 50px
    height: 40px
    font-family: $PoppinsBold
    background: transparent
    border-radius: 16px
    color: #2A1BA1
    border: 1.5px solid #2A1BA1
.colon
    color: $darkGrey900
    font-size: 14px

