@import '../../../components/assets/styles/variables'
@import '../../../components/assets/styles/mixin'
div.wrapper
    //width: 100%
    max-width: 540px
    //padding: 0 5px
    box-sizing: border-box
    flex-direction: column
    position: relative
    border-radius: 16px
    input
        width: 100%
        max-width: 540px
        outline: none
        padding: 12px 24px
        height: 52px
        border-radius: 16px
        border: 1.5px solid #666D82
        background-color: $white
        box-sizing: border-box
        margin-bottom: 5px
        font-family: $Poppins
        font-size: 16px
        color: #666D82
        //&::placeholder
        //    font-family: $Poppins
        //    color: red
        &:invalid
            box-shadow: none
        &.error
            border: 1.5px solid $red900
            &:hover
                border: 1.5px solid $red900
        &:hover
            border: 1.5px solid #000C2F
    .filled
        border: 1.5px solid #000C2F
        color: #000C2F
    textarea
        width: 100%
        outline: none
        padding: 13px 24px
        border: 1.5px solid #666D82
        border-radius: 16px
        background-color: $white
        box-sizing: border-box
        margin-bottom: 5px
        min-height: 98px
        resize: none
        touch-action: manipulation
        font-size: 16px
        &:invalid
            box-shadow: none
        &:hover
            border: 1.5px solid #000C2F
        &.error
            border: 1.5px solid $red900
        .isActiveInput
            border: 1.5px solid #000C2F
.label
    padding: 0 8px
    background-color: $white
    position: absolute
    top: -10px
    left: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    color: $gray100
    z-index: 10
    &__filledLabel
        color: #000C2F
    &.error
        color: #D1291B
.password
    width: 100%
    input
        padding-right: 40px
        touch-action: manipulation
    &__image
        width: 24px
        height: 24px
        position: absolute
        top: 15px
        right: 13px
        // transform: translateY(-50%)
        &:hover
            border: 1.5px solid #000C2F
.message
    color: $red900 !important
    display: flex
    justify-content: flex-end
    font-size: 12px
    line-height: 16px
    min-height: 1rem
.radio
    display: none
    &:checked
        & + .radio__label
    //TODO: some active action!!!
    &__wrapper
        display: block
    &__label
        &:hover
            cursor: pointer
.code
    border: none
    box-sizing: border-box
    border-radius: 16px
    // height: 50px
    width: 100%
    font-size: 16px
    line-height: 24px
    padding: 13px 24px
    font-family: $Poppins
    position: relative
    background: none
    height: 100%
    &:invalid
        box-shadow: none
        &:focus
            border: none
    &__container
        width: 100%
        outline: none
        border: 2px solid $gray10
        border-radius: 16px
        background-color: $white
        box-sizing: border-box
        margin-bottom: 5px
        display: flex
        justify-content: center
        position: relative
        height: 50px
    &__containerError
        width: 100%
        outline: none
        border: 1.5px solid $red900
        border-radius: 16px
        background-color: $white
        box-sizing: border-box
        margin-bottom: 5px
        display: flex
        justify-content: center
        position: relative
        height: 50px
    &__wrapper
        height: 100%
        width: 147px
        position: relative
        box-sizing: border-box
    &__placeholder
        position: absolute
        box-sizing: border-box
        border-radius: 16px
        height: 100%
        width: 100%
        font-size: 16px
        line-height: 28px
        padding: 13px 24px
        font-family: $Poppins
        color: $dark20
        pointer-events: none
        i
            color: transparent
            opacity: 0
            visibility: hidden
.payment
    &__wrapper
        width: 100%
        position: relative
        box-sizing: border-box
        input
            margin-bottom: 0
    &__placeholder
        position: absolute
        box-sizing: border-box
        border-radius: 16px
        //height: 100%
        width: 100%
        font-size: 16px
        line-height: 24px
        height: 24px
        padding: 15px 24px
        font-family: $Poppins
        color: $dark20
        pointer-events: none
        i
            color: transparent
            opacity: 0
            visibility: hidden
// EXPIRE_INPUT_CONTROL
.expire
    width: 100%
    outline: none
    padding: 13px 24px
    //border: 2px solid $gray10
    // box-shadow: 0 0 3px #EEEEF1
    border-radius: 16px
    // background-color: $white
    box-sizing: border-box
    margin-bottom: 5px
    font-size: 16px
    @include r(768)
    &:invalid
        box-shadow: none
    &:focus
        border: 2px solid $violet100
    &__wrapper
        position: relative
        width: 100%
.mask
    position: absolute
    top: 2px
    left: 0
    border: 2px solid transparent
    z-index: -1
textarea
    font-size: 16px
input
    font-size: 16px
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button
    -webkit-appearance: none
input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus
    appearance: none
    -moz-appearance: textfield

