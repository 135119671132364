@import '../../assets/styles/_variables'
@import '../../assets/styles/_mixin'

.select__wrapper
    height: 28px
    position: relative
      
.select__value
    font-family: $Poppins
    font-size: 16px
    line-height: 28px
    color: #4D556D
.dropdown
    &__wrapper
        touch-action: manipulation
        position: absolute
        top: 60px
        left: -190px
        background-color: #FFFFFF
        border-radius: 24px
        box-shadow: 12px 12px 40px rgba(99, 101, 109, 0.08)
        width: 570px
        padding: 24px
        z-index: 101        
        @include r(740)
            position: static
            margin-top: auto
            top: auto
            bottom: 0
            left: 0
            right: 0
            width: 100%
            height: auto
            padding-top: 32px
            padding-left: 16px
            padding-right: 16px
            padding-bottom: 16px
            border-bottom-left-radius: 0
            border-bottom-right-radius: 0        
.search
    @include r(1200) 
      left: 0
.place
  @include r(768)
    position: static
    margin-top: auto
    top: auto
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: auto
    padding-top: 32px
    padding-left: 16px
    padding-right: 16px
    padding-bottom: 16px
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 5, 18, 0.3)
    z-index: 21
    overflow-y: auto 
    display: none  
    @include r(740)
        display: block 
        display: flex
        flex-direction: column
.tabs
    list-style-type: none
    display: flex
    width: 100%
    height: 48px
    border: 1px solid #CCCED5
    border-radius: 16px
    padding: 2px
    @include r(768)
        width: 100%
    @include r(740)
        margin-left: auto
        margin-right: auto
        position: static
.bookingWrapper
    position: static
    @include r(768)
        box-shadow: none
        padding-top: 24px
.filled
    color: $darkGrey900
.tabItem
    font-size: 14px
    font-family: $PoppinsSemiBold
    text-align: center
    cursor: pointer
    width: 33.3333%
    height: 42px
    display: flex
    justify-content: center
    align-items: center
    background: transparent
    position: relative
    z-index: 10
    border: 0
.tabItemFull
    width: 100%
.tabItemHalf
    width: 50%
.slider
    position: absolute
    width: 33.2%
    height: 42px
    background-color: #2A1BA1
    z-index: 0
    border-radius: 16px
    transform: translateX(0)
    transition: 0.4s
.sliderFull
    width: 99%
    left: 3px
.sliderHalf
    width: 50%
.sliderHalfLeft
    transform: translateX(0%)
.sliderRight
    transform: translateX(98%)
.sliderMiddle
    transform: translateX(100%)
.sliderRightFull
    transform: translateX(198%)
.tabItemActive
    color: #FFFFFF
    position: relative
    z-index: 10
//background-color: #2A1BA1
//border-radius: 16px
.tabItemDisabled
    display: none
    background-color: #CCCED5
    border-radius: 16px
.tabs__wrapper
    position: relative
    @include r(740)
        width: 100%
        max-width: 540px
        margin: 0 auto

.contentTitle 
  font-weight: 700
  font-size: 24px


.wrapper 
  position: relative
  //width: fit-content
  @include r(740)
    max-width: 520px
    margin: 0 auto
.line 
  position: absolute
  bottom: -10px
  width: 150px
  height: 3px
  background-color: #000
  transition: 0.5s

.calendar
  position: relative
  top: -20px
  font-family: $Poppins
 
    
.monthInput
  height: 24px
.mobileTop
  margin-bottom: 24px
  position: relative
.mobileTitle
  font-family: $PoppinsSemiBold
  font-size: 18px
  color: #000C2F
  text-align: center
.closeBtn
    position: absolute
    top: -4px
    right: 0
    border: 0
    outline: none
    background: transparent
    padding: 0
.calendarWrapper
    display: flex
    padding-top: 24px
    padding-bottom: 24px
    width: 520px
    justify-content: space-between
    touch-action: manipulation
    @include r(740)
        flex-direction: column
        width: 100%
        max-width: 520px
        margin: 0 auto
.calendarWrapperPlace
  touch-action: manipulation
  @include r(768)
    flex-direction: column
    width: 100%
    max-width: 520px
    margin: 0 auto

.buttonsWrapper
    display: flex
    justify-content: space-between
    @include r(740)
        width: 100%
        max-width: 520px
        margin: 0 auto
.clear
    border: 0
    outline: none
    background: transparent
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #2A1BA1
    display: flex
    align-items: center
    img
        margin-right: 10px

.done
    border: 0
    outline: none
    background: #2A1BA1
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #FFFFFF
    display: flex
    align-items: center
    width: 132px
    height: 50px
    border-radius: 16px
    display: flex
    justify-content: center
    align-items: center
.timeTitle
    font-family: $PoppinsSemiBold
    color: #000C2F
    font-size: 16px
    line-height: 24px
    margin-bottom: 24px
    @include r(740)
      margin-top: 24px
.time
    width: 262px
    height: 56px
    border-radius: 20px
    position: relative
    &__label    
        position: absolute
        color: #666D82
        font-size: 12px
        font-family: $Poppins
        top: -6px
        left: 16px
        background: #FFFFFF
        padding-left: 4px
        padding-right: 4px
        z-index: 10
        &.error
            color: $red900
    &.error
        border: 2px solid $red900
        width: 261.5px
        height: 55.5px
.timeError
        font-family: $Poppins
        font-size: 12px !important
        color: $red900 !important
        line-height: 18px
        @include r(740)
          margin-bottom: 8px
.filled
    color: #000c2f
.timeSelectWrapper
    position: relative
    margin-bottom: 24px
    &:first-child
        @include r(740)
            margin-right: 24px
    @include r(740)
        display: flex
        width: 100%
        margin-bottom: 0
.timeSelectWrapper .timeInput
    @include r(768)
        width: 100%
    //@include r(740)
    //  width: 180px
    //  margin-bottom: 8px
    //@include r(400)
    //  width: 160px
.amButton
    position: absolute
    background: transparent
    top: 16px
    right: 24px
    border: 0
    color: #666D82
    font-family: $PoppinsSemiBold
    font-size: 13px
    @include r(740)
      right: 8px
.timeInput
    width: 228px
    border-radius: 16px
    border: 1.5px solid #666D82
    height: 52px
    padding-left: 16px
    font-family: $Poppins
    @include r(740)
      width: 180px
    @include r(400)
      width: 160px
.inputError
    border: 1px solid $red900
.rightWrapper
    width: 234px
    @include r(740)
      width: 100%
.rightWrapperPlace
  @include r(768)
      width: 100%
.applyBtn
  height: 40px
  padding-left: 16px
  padding-right: 16px
  min-width: 104px
  border-radius: 16px
  border: 0
  color: #FFFFFF
  font-size: 16px
  background: #2A1BA1
  font-family: $PoppinsSemiBold

.timeFlex 
  @include r(740)

    width: 100%
    display: flex
    //justify-content: space-between
    //max-width: 400px
    //margin-left: auto
    //margin-right: auto
    //margin-bottom: 8px
.monthWrapper 
  @include r(740)
    width: 100%
.monthlyWrapper
  width: 252px
  @include r(740)
    margin: 0 auto
.monthlyWrapperPlace
  @include r(768)
    margin: 0 auto
.fullMonthly
  width: 100%
.monthInput
  @include r(740)
    width: 100%
.booking
  position: relative
  top: 0
  left: 0
  box-shadow: 0 4px 24px rgba(212, 209, 236, 0.3)  
.footer
  display: flex  
  justify-content: space-between
  padding-top: 24px
  border-top: 1px solid #F2F3F5
  @include r(740)
    width: 100%
    max-width: 520px
    margin: 0 auto
.text 
  font-size: 14px
  line-height: 20px
  font-family: $Poppins
  color: #808697
  max-width: 280px
  @include r(740)
    display: none
.dailyWrapper
  text-align: center
  padding: 24px 0
  //position: relative
  //left: 20px
  @include r(740)
    left: 0
.calendar__center__line
  width: 1px
  position: absolute
  background: #F2F3F5
  right: 280px
  height: 290px
  top: 98px
.withInput
  top: 176px
.timeSubtitle
  font-size: 14px
  font-family: $Poppins
  line-height: 20px
  color: $darkGrey900
  margin-bottom: 8px
  @include r(768)
    margin-left: 16px
  @include r(550)
    margin-left: 10px
.disabledBtn
  background: #666D82
.timeWrapper 
  @include r(740)
    display: flex
    justify-content: space-between
    @include r(550)
      max-width: 400px
      margin: 0 auto
.timeWrapperPlace
  @include r(768)
    display: flex
    justify-content: space-between
    @include r(550)
      max-width: 400px
      margin: 0 auto
.timeInfo
  font-size: 15px
.clearButton
  background: transparent
  color: #2A1BA1
  font-size: 16px
  font-family: $PoppinsSemiBold
  border-radius: 16px
  border: 1.5px solid #2A1BA1
  width: 100px



