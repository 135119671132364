@import '../../components/assets/styles/_variables'
@import '../../components/assets/styles/_mixin'
.wrapper
    width: 100%
.title
    margin-bottom: 24px
//PROGRESS

.progress
    width: 100%
    height: 2px
    border-radius: 1px
    background-color: $gray10
    margin-bottom: 32px
    overflow: hidden
    &__item
        height: 2px
        background-color: #2A1BA1
        border-radius: 1px
div.depositError
    font-size: 12px
    color: #ff4d97
    position: relative
    top: -30px
.small
    &__title
        color: $darkGrey900
        font-size: 20px
        font-family: $Poppins
        margin-bottom: 8px
    &__subtitle
        color: $darkGrey900
        font-size: 14px
        font-family: $Poppins
        line-height: 20px
        margin-bottom: 16px
div.subtitle
    color: $dark100
    margin-bottom: 39px
    &_bold
        font-family: $PoppinsSemiBold
        font-style: normal
        font-size: 1.8rem
        line-height: 3.0rem
        margin-bottom: 0
    &__price
        margin-top: 80px
.button__container
    display: flex
    justify-content: space-between
    padding-left: 30px
    padding-right: 30px
    @include r(550)
        padding-left: 16px
        padding-right: 16px
button.button
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: $white
    background: #2A1BA1
    padding-left: 60px
    padding-right: 60px
    height: 50px
    border-radius: 16px
    margin-left: 30px
    @include r(1200)
        padding-left: 24px
        padding-right: 24px
    &:disabled
        background-color: $dark20
    @include r(425)
        padding-left: 16px
        padding-right: 16px
button.back
    font-family: $PoppinsSemiBold
    color: #2A1BA1
    font-size: 16px
    line-height: 20px
    padding: 0
    background: none
    &:hover
        background: none
    svg
        margin-right: 16px
.select
    margin-bottom: 31px
    height: 54px
    input
        font-family: $Poppins
        font-size: 1.6rem
        @include r(820)
            font-size: 16px
        line-height: 2.4rem
        color: #000C2F
div.input
    @extend .select
    height: auto
    margin-bottom: 11px
    input, textarea
        @extend .select
        height: auto
        margin-bottom: 11px
        font-family: $Poppins
        border: 1.5px solid #666D82
        font-size: 16px
        color: #000C2F !important
        @include r(820)
            font-size: 16px
        line-height: 2.4rem
        color: #666D82
        &::placeholder
            color: #666D82
            font-size: 16px
            font-family: $Poppins
.half
    width: calc(50% - 17px)
    &__wrapper
        display: flex
        justify-content: space-between
//STEP 2
.switch
    margin-bottom: 8px
    &__wrapper
        margin-bottom: 32px
//FETURES
.featuresAvaible
    margin-bottom: 8px
.checkbox
    margin-right: 12px
    &__wrapper
        display: flex
        align-items: center
        span
            font-family: $Poppins
            font-style: normal
            font-weight: normal
            font-size: 1.4rem
            line-height: 2.0rem
            color: $gray100
        a
            font-family: $Poppins
            font-style: normal
            font-weight: normal
            font-size: 1.4rem
            line-height: 2.0rem
            color: $violet100
            text-decoration: none
.flex
    display: flex
    height: 100%
    min-height: 100vh
    @include r(990)
        flex-direction: column
.rightSide
    width: 50%
    height: 100%
    @include r(990)
        width: 100%
        background: #ffffff
        position: relative
        height: calc(100vh - 500px)
    @include r(550)
        height: calc(100vh - 394px) 
.rightSideTop
    display: none
    position: absolute
    top: -24px
    width: 100%
    height: 25px
    border-top-right-radius: 16px
    border-top-left-radius: 16px
    background: #FFFFFF
    @include r(990)
        display: block
.form
    display: flex
    flex-direction: column
    height: calc(100% - 106px)
    padding-bottom: 20px
    min-height: calc(100vh - 106px)
    @include r(990)
        height: 100%
        min-height: 0
        padding-bottom: 0
.footer
    margin-top: auto
    padding-bottom: 20px
.leftSide
    width: 50%
    position: relative
    @include r(990)
        width: 100%
        height: 500px
    @include r(550)
        height: 394px
.exitMobile
    position: absolute
    top: 40px
    left: 40px
    background: transparent
    border: 0
    display: none
    @include r(990)
        display: block
    @include r(550)
        top: 24px
        left: 24px
.helpMobile
    position: absolute
    top: 40px
    right: 40px
    display: none
    color: #ffffff
    font-size: 16px
    font-family: $PoppinsSemiBold
    @include r(990)
        display: block
    @include r(550)
        top: 24px
        right: 24px
.inputs__wrapper
    width: 540px
    margin-left: auto
    margin-right: auto
    padding-top: 40px
    @include r(1130)
        width: 100%
        padding-left: 15px
        padding-right: 15px
    @include r(990)
        width: 100%
        max-width: 600px
    @include r(550)
        padding-top: 0
    &--noSpaces
        @include r(374)
            padding-left: 0
            padding-right: 0
.address_wr
    @include rh(900)
        margin-bottom: 50px
    @include r(990)
        margin-bottom: 70px
.left__background
    width: 100%
    height: 100%
    padding-top: 148px
    padding-left: 64px
    background-repeat: no-repeat
    background-size: cover
    height: 100vh
    position: fixed
    width: 50%
    @include r(1160)
        padding-left: 32px
    @include r(990)
        height: 500px
        position: static
        width: 100%
    @include r(550)
        height: 394px
.first
    background: url('./assets/office1.jpg')
    background-repeat: no-repeat
    background-size: cover
.second
    background: url('./assets/office2.jpg')
    background-repeat: no-repeat
    background-size: cover
.third
    background: url('./assets/office3.jpg')
    background-repeat: no-repeat
    background-size: cover
.fourth
    background: url('./assets/office4.jpg')
    background-repeat: no-repeat
    background-size: cover
.fifth
    background: url('./assets/office5.jpg')
    background-repeat: no-repeat
    background-size: cover
.sixth
    background: url('./assets/office6.jpg')
    background-repeat: no-repeat
    background-size: cover
.seventh
    background: url('./assets/office7.jpg')
    background-repeat: no-repeat
    background-size: cover
.eighth
    background: url('./assets/office8.jpg')
    background-repeat: no-repeat
    background-size: cover
    height: 100vh
    position: fixed
    width: 50%
    @include r(990)
        height: 500px
        position: static
        width: 100%
    @include r(550)
        height: 394px
.ninth
    background: url('./assets/office9.jpg')
    background-repeat: no-repeat
    background-size: cover
.tenth
    background: url('./assets/office10.jpg')
    background-repeat: no-repeat
    background-size: cover
.eleventh
    background: url('./assets/office11.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__first
    background: url('./assets/coworking1.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__second
    background: url('./assets/coworking2.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__third
    background: url('./assets/coworking3.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__fourth
    background: url('./assets/coworking4.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__fifth
    background: url('./assets/coworking5.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__sixth
    background: url('./assets/office9.jpg')
    background-repeat: no-repeat
    background-size: cover
.coworking__seventh
    background: url('./assets/coworking6.jpg')
    background-repeat: no-repeat
    background-size: cover
.titleWrapper
    @include r(990)
        position: absolute
        top: 80px
        left: 40px
    @include r(550)
        top: 60px
        left: 24px
.titleWrapper9
    @include r(1230)
        position: absolute
        left: 24px
.title
    font-family: $PoppinsLight
    font-size: 64px
    line-height: 72px
    color: #FFFFFF
    margin-bottom: 24px
    max-width: 700px
    @include r(990)
        font-size: 48px
        line-height: 58px
        padding-right: 16px
    @include r(550)
        font-size: 40px
        line-height: 50px
.title9
    @include r(1130)
        font-size: 58px
    @include r(1020)
        font-size: 50px
    @include r(550)
        font-size: 40px
        line-height: 50px
.subtitle
    font-family: $Poppins
    font-weight: 300
    font-size: 16px
    line-height: 28px
    color: #FFFFFF
    max-width: 500px
    @include r(990)
        padding-right: 16px
.logo
    img
        width: 165px
        height: 44px
.header
    padding: 30px
    display: flex
    justify-content: space-between
    align-items: center
    @include r(990)
        display: none
.help
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: $darkGrey900
    margin-right: 32px
.exit
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #2A1BA1
    padding: 10px 24px
    border-radius: 16px
    background: transparent
    outline: none
    border: 2px solid #2A1BA1
.radio
    display: block
    margin-bottom: 24px
.description
    display: block
    border: 1px solid #CCCED5
    border-radius: 32px
    padding: 24px
    cursor: pointer
    &__title
        display: block
        font-size: 16px
        color: #000C2F
        font-family: $PoppinsSemiBold
        line-height: 20px
        margin-bottom: 8px
    &__subtitle
        display: block
        font-size: 14px
        color: #000C2F
        font-family: $Poppins
        line-height: 20px
    &__coworking
        display: flex
        align-items: center
.title__coworking
    margin-bottom: 0
.radio input:checked ~ .description
    border: 1px solid #2A1BA1
    background: #F3F2FA
    .description__title,
    .description__subtitle
        color: #2A1BA1
    .circle
        border: 1px solid #2A1BA1
        &:after
            display: block
.circle
    width: 24px
    height: 24px
    border: 1px solid #666D82
    border-radius: 50%
    display: block
    position: relative
    margin-right: 16px
    &:after
        content: ''
        display: block
        width: 16px
        height: 16px
        border-radius: 50%
        background: #2A1BA1
        position: absolute
        top: 3px
        left: 3px
        display: none
.link__coworking
    margin-bottom: 16px
.days__wrapper
    display: flex
    padding-top: 24px
    padding-bottom: 24px
    border-bottom: 1px solid #CCCED5
    justify-content: space-between
    p
        font-family: $Poppins
        color: #000C2F
        font-size: 16px
        line-height: 28px
.wrapper__input
    position: relative
    display: flex
    img
        position: absolute
        right: 0
        pointer-events: none
.days__input
    padding: 0
    border: 0
    width: 190px
    cursor: pointer
    text-align: right
    padding-right: 32px
    &::placeholder
        color: #666D82
        font-family: $Poppins
.switcher
    display: block
.priceMemberPasses
    padding-top: 24px
    padding-bottom: 24px
    border-bottom: 1px solid #CCCED5
.price
    padding-top: 24px
    padding-bottom: 24px
    border-bottom: 1px solid #CCCED5
    &__input
        margin-top: 16px
    &__header
        display: flex
        justify-content: space-between
    &__label
        font-family: $Poppins
        font-size: 16px
        color: #000C2F
.deposit
    width: 350px
    margin-left: 100px
    margin-top: 80px
    &__buttons
        display: flex
        justify-content: space-between
        margin-bottom: 24px
    &__text
        margin-top: 24px
    &__button
        width: 160px
        height: 160px
        border-radius: 32px
        border: 1px solid #CCCED5
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        cursor: pointer
        font-family: $PoppinsSemiBold
        color: #000C2F
        margin-top: 20px
        font-size: 16px
        &.btnActive
            border: 1px solid $violet100
            background: #F3F2FA
            color: $violet100
.options__wrapper
    display: flex
    flex-wrap: wrap
    width: 474px
    justify-content: space-between
    padding-bottom: 22px
    @include r(990)
        margin-left: auto
        margin-right: auto
        margin-bottom: 16px
    @include r(550)
        width: 100%
        margin: 0 auto  
.option
    min-width: 40px
    height: 40px
    border-radius: 40px
    border: 1px solid #666D82
    color: #666D82
    font-family: $Poppins
    font-size: 16px
    color: #666D82
    display: flex
    justify-content: center
    align-items: center
    margin-left: 8px
    margin-right: 8px
    margin-bottom: 16px
    @include r(550)
        min-width: 8.5vw
        height: 8.5vw
        margin-left: 0
        margin-right: 3.2vw
        margin-bottom: 3.2vw 
    @include r(450)
        min-width: 10.6vw
        height: 10.6vw
        font-size: 4.2vw
        margin-right: 4.2vw
        margin-left: 0
        margin-bottom: 4.2vw 
    &:nth-child(8n)
        @include r(550)
            margin-right: 0
        @include r(450)
            margin-right: 4.2vw  
    &:nth-child(6n)
        @include r(450)
            margin-right: 0    
    &__bottom
        width: 96px
        &:nth-child(25),
        &:nth-child(26),
        &:nth-child(27),
        &:nth-child(28)
            @include r(550)
                margin-right: 0
        @include r(550)
            width: 19.6vw
        
    &:hover
        background-color: #F3F2FA
        color: #4135AC
        border: 1px solid #4135AC
        cursor: pointer
    &.optionActive
        background-color: #2A1BA1
        color: #FFFFFF
        border: 1px solid #2A1BA1
.guests__title
    font-family: $Poppins
    font-size: 20px
    line-height: 30px
    color: #000C2F
    margin-bottom: 22px
    @include r(990)
        width: 474px
        margin-left: auto
        margin-right: auto
    @include r(550)
        width: 100%
.step7__wrapper
    width: 474px
    margin-top: 80px
    margin-left: 40px
    @include r(1200)
        width: 100%
        padding-right: 20px
        margin-left: 0
    @include r(990)
        padding-right: 0
        width: 100%
        margin-left: 0
        margin-top: 0
    @include r(550)
        width: 100%
.amenitiesTitle
    font-family: $Poppins
    font-size: 20px
    color: #000C2F
.amenitiesWrapper
    width: 570px
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    margin-left: -15px
    margin-right: -15px
    margin-top: -15px
    padding-bottom: 15px
    @include r(1160)
        width: 100%
    @include r(990)
        width: 570px
        margin-left: 0
    @include r(768)
        width: 380px
        margin-left: auto
        margin-right: auto
    @include r(400)
        width: 100%
.amenitiesTop
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 30px
.amenity
    border-radius: 100px
    padding: 12px 20px
    border: 1px solid #CCCED5
    display: flex
    flex-direction: row
    align-items: center
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #1A2444
    margin: 6px
    @include r(550)
        font-size: 12px
        padding: 8px 16px
    .icon
        color: #1A2444
        margin-right: 8px
    &:hover
        cursor: pointer
        color: #2A1BA1
        border: 1px solid #2A1BA1
        .icon
            color: #2A1BA1
    &.amenityActive
        border: 1px solid #2A1BA1
        color: #FFFFFF
        background-color: #2A1BA1
        .icon
            color: #FFFFFF
.amenityTitle
    text-align: center
.icon
    color: #515970
.pricingWrapper
    margin-top: 80px
    @include r(990)
        margin-top: 0
.capacity__wrapper
    margin-top: 32px
button.draftBtn
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #2A1BA1
    padding: 10px 24px
    border-radius: 16px
    background: transparent
    outline: none
    border: 2px solid #2A1BA1
    @include r(1100)
        padding: 10px 8px
    @include r(990)
        padding: 10px 24px
.address__wrapper
    margin-top: 80px
    @include r(990)
        margin-top: 0
.title__add
    font-family: $Poppins
    font-size: 20px
    line-height: 30px
    color: $darkGrey900
    @include r(420)
        margin-bottom: 12px
.create__link, .duplicate
    display: flex
    width: 100%
    align-items: center
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: $darkGrey900
    padding: 25px
    border: 1px solid #CCCED5
    border-radius: 32px
    margin-bottom: 24px
.duplicate
    background: transparent
    outline: none
.plus
    margin-right: 16px
.arrow
    margin-left: auto
.add__wrapper
    margin-top: 80px
    @include r(990)
        margin-top: 0
.add__wrapper_coworking
    margin-top: 40px
    @include r(990)
        margin-top: 0
.exitDisabled
    color: #CCCED5
    border: 2px solid #CCCED5
.passesWrapper
//margin-top: 80px
.daily__input
    margin-bottom: 24px
.lastStepBtns
    @include r(550)
        display: flex
        flex-direction: column
        .button
            order: -1
            margin-bottom: 24px
.button__container_last
    @include r(550)
        flex-direction: column
        .button
            margin-left: 0
        .draftBtn
            margin-bottom: 24px
        .back
            text-align: left
            order: 10
            margin: 0 auto
.visuallyHidden
    position: absolute
    white-space: nowrap
    width: 1px
    height: 1px
    overflow: hidden
    border: 0
    padding: 0
    clip: rect(0 0 0 0)
    clip-path: inset(50%)
    margin: -1px
    display: none
.photo_wr
    @include r(990)
        padding-top: 0
.coworkingTop
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 12px
    @include r(420)
        flex-direction: column
        align-items: flex-start
.coworkingAdd
    background: transparent
    border: 0
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #2A1BA1
    display: flex
    align-items: center
    white-space: nowrap
.smallPlus
    margin-left: 4px
.createCoworkingLink
    width: 540px
    display: block
    padding: 24px
    font-size: 20px
    line-height: 30px
    font-family: $Poppins
    color: $darkGrey900
    border-radius: 32px
    border: 1px solid #CCCED5
    @include r(1200)
        width: 100%
    @include r(990)
        margin-bottom: 72px
    @include r(374)
        padding: 24px 15px
.modal__space
    display: flex
    align-items: center
    background: transparent
    width: 100%
    font-size: 16px
    line-height: 20px
    color: $darkGrey900
    font-family: $PoppinsSemiBold
    padding: 24px
    border: 1px solid #CCCED5
    border-radius: 32px
    margin-bottom: 24px
    cursor: pointer
    transition: all 0.25s ease
    .spaceKind
        color: #666D82
        font-family: $Poppins
        line-height: 28px
    &:hover
        border: 1px solid #4135AC
        box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08)
        .spaceName
            color: #4135AC
        .spaceKind
            color: #4135AC
.space__image
    margin-right: 16px
    border-radius: 16px
.badgeWrapper
    margin-left: auto
.subtitlePriceCoworking
    color: $darkGrey900
    font-size: 20px
    line-height: 30px
.size_occupancy
    margin-top: 32px
.capacity_select
    margin-top: 32px
.monthWrap
    display: flex
    justify-content: space-between
    @include r(990)
        flex-direction: column
.wr
    width: 49%
    @include r(990)
        width: 100%
.image
    margin-right: 8px
.item
    display: flex
    align-items: center
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #1A2444
    padding: 8px 16px
    border-radius: 100px
    width: fit-content
    border: 1px solid #CCCED5
    margin-right: 10px
    margin-bottom: 10px
    @include r(550)
        font-size: 12px
    &:hover
        cursor: pointer
        color: #2A1BA1
        border: 1px solid #2A1BA1
        .icon
            color: #2A1BA1
.icon
    color: #1A2444
.itemActive
    background: #2A1BA1
    color: #ffffff
    border: 1px solid #2A1BA1
    .icon
        color: #ffffff
    &:hover
        cursor: pointer
        background: #2A1BA1
        color: #ffffff
        .icon
            color: #ffffff
.amenitiesWr
    display: flex
    flex-wrap: wrap
    width: 100%
    margin-top: 20px
    margin-bottom: 24px
.amenitiesTopChild
    display: flex
    justify-content: space-between
    margin-top: 32px
    margin-bottom: 32px
.amenitiesTitleChild
    font-family: $Poppins
    font-size: 20px
    line-height: 30px
    color: $darkGrey900
.paginatorWrapper
    padding-bottom: 24px
.titleWrSecond
    position: fixed
    width: 700px
    @include r(1300)
        width: 550px
    @include r(1200)
        width: 500px
    @include r(990)
        position: absolute
    @include r(480)
        width: 400px
    @include r(480)
        width: 320px
.subtitleTwo
    @include r(1100)
        width: 450px
    @include r(480)
        width: 400px
    @include r(400)
        width: 320px


.removeScrollBar
    position: fixed
    width: 100%
    @include r(990)
        position: static
        width: auto
        overflow: hidden
.greatText
    font-family: $Poppins
    font-size: 20px
    line-height: 30px
    color: $darkGrey900
    margin-bottom: 32px
    @include r(550)
        font-size: 18px
        line-height: 28px
.atLeastOne
    font-size: 16px
    line-height: 28px
    font-family: $Poppins
    color: $darkGrey900
    margin-bottom: 32px
    @include r(550)
        font-size: 14px
        line-height: 24px