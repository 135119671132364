@import '../../../components/assets/styles/variables'
@import '../../../components/assets/styles/mixin'
.mainWrapper
    padding: 0 10px
.nativeBox
    display: flex
    flex-direction: column
    width: 100%
    justify-content: space-between
    padding: 0 10px
.forCamModal
    opacity: 0.3
    background: #000C2F
.businessWrapper
    display: flex
    flex-direction: column
    width: 100%
    min-height: 100px
    max-width: 540px
    //padding: 0 10px
    margin: 0 auto
    padding-bottom: 100px
    @include r(768)
        padding-bottom: 0
    @include r(560)
        margin-top: 34px
//margin-top: 90px
.businessTitleBox, .businessInput, .inputBox, .idVerifyTitleBox
    display: flex
    flex-direction: column
    width: 100%
    max-width: 540px
//margin-bottom: 32px
.verifyInput
    margin-bottom: 24px
.inputBox
    position: relative
    margin-top: 24px
//@include r(560)
//    max-width: 380px
//    margin: 0 auto
.businessMainTitle
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 40px
    line-height: 50px
    color: #000C2F
    margin-bottom: 12px
.businessSubtitle
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 28px
    color: #666D82
    margin-bottom: 32px
.businessSubtitleIndividual
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 28px
    color: #666D82
//---------------------------------
.descriptionTitleBox
    display: flex
    flex-direction: column
.businessTitle, .descriptionTitle
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 30px
    color: #000C2F
    margin-bottom: 24px
.descriptionCheckbox
    display: flex
    margin-top: 24px
    margin-bottom: 26px
.businessMainInputBox
    margin-top: 26px
//-------------------owner box----------------------
.ownerCardBox
    display: flex
    flex-direction: column
    width: 100%
    max-width: 540px
    border: 1px solid #CCCED5
    border-radius: 32px
    padding: 32px
    margin-bottom: 40px
.headerOwner
    display: flex
    justify-content: space-between
    margin-bottom: 20px
.btnOwner
    font-family: $PoppinsSemiBold
    font-size: 1.6rem
    line-height: 2.0rem
    color: $violet800
    background: $white
    padding: 10px 0
    border-radius: 16px
    border: 1.5px solid #2A1BA1
    width: 249px
    height: 40px
    margin-bottom: 74px
    @include r(768)
        width: 100%
.btnSvg
    margin-right: 7px
//-------------modal checkbox--------------------
.checkboxWebsite, .checkboxDescription, .idVerifyConteiner
    display: flex
    width: 100%
    max-width: 540px
    margin-bottom: 48px
    textarea
        font-family: $Poppins
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 28px
        color: #000C2F
        border: 1.5px solid #666D82
//.opacityBox
//    background: #000C2F
//    opacity: 0.3
.hiddenWebsite, .hiddenDescription
    display: none
//---------------------------------
.button__container
    display: flex
    justify-content: space-between
    width: 100%
    max-width: 540px
    margin: 0 auto
    margin-bottom: 120px
button.button
    font-family: $PoppinsSemiBold
    font-size: 1.6rem
    line-height: 2.0rem
    color: $white
    background: $violet800
    padding-left: 60px
    padding-right: 60px
    height: 50px
    border-radius: 16px
    @include r(560)
        width: 100%
    &:disabled
        background: $darkgrey500
.backBtn
    font-family: $PoppinsSemiBold
    color: #2A1BA1
    font-size: 1.8rem
    line-height: 3.0rem
    padding: 0
    background: none
    text-align: start
    @include r(560)
        margin-top: 18px
    svg
        margin-right: 6px
//-------------------------modal---------------
.inputModalSvgBox
    display: block
    width: 16px
    height: 16px
    position: absolute
    top: 15px
    left: 490px
    cursor: pointer
    @include r(560)
        left: 480px
    @include r(545)
        left: 460px
    @include r(525)
        left: 440px
    @include r(510)
        left: 420px
    @include r(485)
        left: 400px
    @include r(465)
        left: 380px
    @include r(445)
        left: 360px
    @include r(425)
        left: 340px
    @include r(405)
        left: 320px
    @include r(390)
        left: 300px
.inputModalSvgBox:hover + .inputModal
    display: block
.inputModal
    display: none
    position: absolute
    top: 43px
    left: 480px
    width: 485px
    height: 130px
    background: #FFFFFF
    box-shadow: 0 4px 24px rgba(212, 209, 236, 0.3)
    border-radius: 16px
    padding: 24px
    z-index: 20
    @include r(1460)
        left: 400px
    @include r(1280)
        left: 320px
    @include r(1280)
        left: 280px
    @include r(1130)
        left: 210px
    @include r(880)
        left: 140px
    @include r(760)
        left: 60px
    @include r(590)
        width: 320px
        height: 160px
        left: 100px
    @include r(560)
        width: 320px
        height: 160px
        left: 24px
    @include r(400)
        width: 320px
        height: 160px
        left: 6px
.hiddenModal
    display: none
.inputModalText
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
//---------------------------progress-bar-------------------------------
.progressBox
    display: flex
    width: 100%
    max-width: 540px
    margin: 0 auto
    justify-content: center
    margin-bottom: 66px
.progressNumber
    display: flex
    min-width: 40px
    height: 40px
    border: 1px solid #03050a
    border-radius: 50px
    align-items: center
    justify-content: center
    margin-right: 8px
    &:last-child
        margin-right: 0
.progressNumberReady
    display: flex
    min-width: 40px
    height: 40px
    border: 1px solid #666D82
    background-color: $violet800
    border-radius: 50px
    align-items: center
    justify-content: center
    margin-right: 8px
.progressNumberBorder
    border: 1px solid $violet800
.progressLine
    height: 3px
    max-width: 69px
    background-color: #F3F2FA
    border-radius: 1px
    width: 100%
    margin-top: 18px
    margin-right: 8px
    @include r(560)
        width: 105px
    @include r(540)
        width: 100px
    @include r(520)
        width: 95px
    @include r(500)
        width: 90px
    @include r(480)
        width: 90px
    @include r(460)
        width: 85px
    @include r(440)
        width: 75px
    @include r(420)
        width: 45px
.progressLineReady
    height: 3px
    max-width: 69px
    background-color: $violet800
    border-radius: 1px
    width: 100%
    margin-top: 18px
    margin-right: 8px
    @include r(560)
        width: 105px
    @include r(540)
        width: 100px
    @include r(520)
        width: 95px
    @include r(500)
        width: 90px
    @include r(480)
        width: 85px
    @include r(460)
        width: 80px
    @include r(440)
        width: 70px
    @include r(420)
        width: 45px
.progressLineReady5
    height: 3px
    background-color: $violet800
    border-radius: 1px
    width: 69px
    margin-top: 18px
    margin-right: 8px
.progressLine5
    height: 3px
    background-color: #F3F2FA
    border-radius: 1px
    width: 69px
    margin-top: 18px
    margin-right: 8px
.progressSpan
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 28px
    text-align: center
    color: #666D82
//---------------------------step-2-------------------------------
.calendarIcon
    width: 16px
    height: 16px
    position: absolute
    top: 15px
    left: 480px
    @include r(560)
        left: 460px
    @include r(530)
        left: 450px
    @include r(520)
        left: 440px
    @include r(510)
        left: 430px
    @include r(490)
        left: 400px
    @include r(480)
        left: 400px
    @include r(460)
        left: 380px
    @include r(450)
        left: 370px
    @include r(440)
        left: 350px
    @include r(430)
        left: 340px
    @include r(420)
        left: 330px
    @include r(420)
        left: 320px
    @include r(410)
        left: 320px
    @include r(380)
        left: 320px
.calendarBox
    position: relative
    width: 100%
    max-width: 540px
.addressInput
    width: 49% !important
    margin-bottom: 24px
    @include r(768)
        width: 100% !important
.addressInputZip
    width: 49% !important
    margin-bottom: 24px
    height: 50px !important
    @include r(768)
        width: 100% !important
.addressInputState
    width: 49%
    margin-bottom: 24px
    @include r(560)
        width: 100%
.addressInputBox
    display: flex
    flex-direction: column
    margin-top: 26px
.addressInputWrap
    display: flex
    justify-content: space-between
    //flex-direction: column
    //margin-bottom: 32px
    @include r(560)
        flex-direction: column
        margin-bottom: 0
.addressInputWrapPayment
    display: flex
    justify-content: space-between
    @include r(560)
        flex-direction: column
        margin-bottom: 0
.modalCheckbox
    display: flex
    flex-direction: column
.cheboxDiv
    width: 40px
    height: 40px
//---------------------------step-2-------------------------------
.businessMainTitle
    font-size: 38px
//---------------------------step-3-------------------------------
.radioContainer
    display: flex
    flex-direction: column
    width: 100%
.radioField
    width: 100%
    max-width: 540px
    height: 88px
.radio
    display: flex
    width: 100%
    max-width: 540px
    height: 88px
    border: 1px solid #CCCED5
    border-radius: 32px
    margin-bottom: 18px
    background-color: #fff
    align-items: center
    padding-left: 38px
    &:disabled
        background: #c6c8ce
        &:hover
            cursor: auto
    svg
        margin-right: 22px
    &:focus
        background-color: #F3F2FA
        border: 1px solid #2A1BA1
.radio_selected
    display: flex
    width: 100%
    max-width: 540px
    height: 88px
    border-radius: 32px
    margin-bottom: 18px
    align-items: center
    padding-left: 38px
    background-color: #F3F2FA
    border: 1px solid #2A1BA1
    svg
        margin-right: 22px
    &:hover
        cursor: auto
.radioTitle
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    text-align: center
    color: #000C2F
    @include r(560)
        font-size: 14px
//---------------------------Firs button-------------------------------
.firstBtnCont, .smsBox
    display: flex
    flex-direction: column
    width: 100%
    max-width: 540px
.tabBar
    position: relative
    width: 100%
    max-width: 540px
    height: 50px
    border-radius: 16px
    border: 1px solid #CCCED5
    display: flex
    justify-content: space-between
    padding: 4px
    margin-bottom: 32px
//display: flex
//width: 100%
//max-width: 540px
//height: 50px
//border: 1px solid #CCCED5
//border-radius: 16px
//justify-content: space-between
//align-items: center
//margin-bottom: 32px
//padding: 0 2px
//transition: 0.4s
//position: relative
.barButton
    justify-content: center
    align-items: center
    width: 125px
    height: 42px
    padding: 0 1px
    background: #FFFFFF
    border-radius: 16px
    border: none
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 20px
    text-align: center
    color: #000C2F
    @include r(768)
        width: 83px
    &:focus
        background-color: #2A1BA1
        color: #FFFFFF
    &__selected
        background-color: #2A1BA1
        color: #FFFFFF
.secondBarButton
    justify-content: center
    align-items: center
    width: 33%
    height: 42px
    padding: 0 2px
    background: transparent
    border-radius: 16px
    border: none
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 16px
    text-align: center
    color: #000C2F
    transition: 0.4s
    text-transform: capitalize
    &:hover
        cursor: pointer
    &:disabled
        background: #c6c8ce
        width: 32%
    &__selected
        justify-content: center
        text-align: center
        width: 33%
        height: 42px
        padding: 0 2px
        border-radius: 16px
        border: none
        font-family: $Poppins
        font-style: normal
        font-weight: 600
        font-size: 14px
        line-height: 16px
        background-color: #2A1BA1
        color: #FFFFFF
        transition: 0.4s
.sliderFirst
    transform: translateX(calc(0% - 0px))
.sliderSecond
    transform: translateX(calc(100% - 3px))
.sliderThird
    transform: translateX(calc(200% - 4px))
.sliderFour
    transform: translateX(calc(300% - 4px))
.sliderFirstSecond
    transform: translateX(calc(0% - 1px))
.sliderSecondSecond
    transform: translateX(calc(100% - 0px))
.sliderThirdSecond
    transform: translateX(calc(200% - 1px))
.sliderThirdSecondImg
    transform: translateX(calc(200% - 1px))
.sliderFirstBtn
    position: absolute
    top: 3px
    left: 3px
    width: 25%
    height: 42px
    background: #2A1BA1
    border-radius: 16px
    z-index: -1
    transition: 0.4s
    display: flex
.sliderVerify
    position: absolute
    top: 3px
    left: 3px
    width: 33%
    height: 42px
    background: #2A1BA1
    border-radius: 16px
    z-index: -1
    transition: 0.4s
    display: flex
.activeTabVerify
    color: #ffffff
.smsTitleBox
    display: flex
    flex-direction: column
    margin-bottom: 24px
.smsTitle
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 20px
    line-height: 30px
    color: #1E1E1E
    margin-bottom: 12px
.inputPhoneControl input
    padding: 15px 24px !important
.smsSubTitle
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 28px
    color: #666D82
.sms2
    margin-bottom: 12px
.sendLinkBtn
    justify-content: center
    align-items: center
    padding: 15px 106px
    height: 50px
    border: 1.5px solid #2A1BA1
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    text-align: center
    color: #2A1BA1
    background-color: #fff
    width: 100%
    max-width: 540px
//---------------------------Firs button-------------------------------
.video
    border: 1px solid #000
//---------------------------mini screenshot-------------------------------
.miniPicBox
    width: 110px
    height: 100px
    border: 1px solid #CCCED5
    border-radius: 16px
    position: relative
    margin: 10px 12px 10px 0
.miniPic
    width: 100px
    height: 64px
    filter: blur(4px)
    margin-left: 4px
    margin-top: 16px
.miniPicCloseBox
    width: 40px
    height: 40px
    background: white
    border-radius: 50%
    box-shadow: -2px 8px 20px #D4D1EC
    position: absolute
    top: 0
    left: 76px
    padding: 8px
    z-index: 100
//---------------------------industry selector------------------------------
.industry
    width: 100%
    margin-bottom: 20px
    position: relative
    &__error
        display: flex
        margin-right: 0
        justify-content: end
        color: #D1291B !important
        font-size: 12px
        margin-top: 8px
        font-family: "Poppins", sans-serif
    &__label
        padding: 0 8px
        background-color: #ffffff
        position: absolute
        top: -8px
        left: 16px
        font-family: "Poppins", sans-serif
        font-style: normal
        font-weight: normal
        font-size: 12px
        line-height: 16px
        color: #666D82
        z-index: 10
        &__error
            color: #D1291B !important
            padding: 0 8px
            background-color: #ffffff
            position: absolute
            top: -8px
            left: 16px
            font-family: "Poppins", sans-serif
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 16px
            z-index: 10
        &__filled
            color: #000C2F
.imageWrapper
    display: flex
    justify-content: start
    flex-wrap: wrap
//div.select
//    &__container
//        &__menu-list
//            &::-webkit-scrollbar-track
//                border-radius: 10px
//                background-color: #ffffff
//            &::-webkit-scrollbar
//                width: 6px
//                background-color: #F5F5F5
//            &::-webkit-scrollbar-thumb
//                border-radius: 10px
//                background-color: $violet100
.menuList
    &::-webkit-scrollbar-track
        border-radius: 10px
        background-color: #ffffff
    &::-webkit-scrollbar
        width: 6px
        background-color: #F5F5F5
    &::-webkit-scrollbar-thumb
        border-radius: 10px
        background-color: $violet100
.button__start
    display: flex
    justify-content: center
    align-items: center
    //padding: 15px 60px
    width: 258px
    height: 50px
    color: $white
    background: $violet800
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    text-align: center
    border: none
    @include r(560)
        width: 100%

.verifyCheckboxWrapper
    display: flex
    align-items: center
    margin-top: 16px
.verifyCheckbox
    flex-shrink: 0
.verifyCheckboxLabel
    font-size: 16px
    line-height: 28px
    color: #666D82
    font-family: $Poppins
    margin-left: 16px
.removeScrollBar
    overflow: hidden