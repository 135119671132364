@import '/components/assets/styles/_reset'
@import '/components/assets/styles/_fonts'
@import '/components/assets/styles/_mixin'
@import '/components/assets/styles/_variables'
*
    outline: none
    box-sizing: border-box
html, body
    min-height: 100vh
    max-width: 100%
    font-size: 10px
    @include r(768)
        font-size: 8px
html
    scrollbar-color: $violet100 #F3F0F0
    -webkit-text-size-adjust: none
body
    margin: 0
    padding: 0
    overflow-y: scroll
    background-color: $white
    overflow-x: hidden
    //touch-action: pan-x pan-y
    &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
        border-radius: 10px
        background-color: #F5F5F5
    &::-webkit-scrollbar
        width: 12px
        background-color: #F5F5F5
    &::-webkit-scrollbar-thumb
        border-radius: 10px
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
        background-color: $violet100
.app
    &__container
        max-width: 1500px
        margin: 0 auto
        // border: 1px solid red
        @include r(1500)
            padding: 0 16px
        &--full
            display: flex
            width: 100%
            height: 100%
            position: relative
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
    transition: background-color 5000s
    -webkit-text-fill-color: $darkGrey900 !important
input
    filter: none
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none
textarea
    appearance: none
    -moz-appearance: none
    -webkit-appearance: none
    &::-webkit-scrollbar
        width: 10px
    &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
        border-radius: 20px
        margin: 5px 0
    &::-webkit-scrollbar-thumb
        border-radius: 10px
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5)
        background-color: $violet100
a
    text-decoration: none
button:hover
    cursor: pointer
.page_wrapper
    min-height: calc(100vh - 108px)
    @include r(990)
        min-height: auto
        display: flex
        justify-content: center