@import ../../assets/styles/_variables
.wrapper
    background: #FFFFFF
    border: 2px solid $darkgrey500
    box-sizing: border-box
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
    &:hover
        cursor: pointer
    input[type="checkbox"]
        display: none
        &:checked + .custom
            display: block
.checked
    border: 2px solid #4135AC
.custom
    display: none
input, textarea
    font-size: 16px
//For switcher

$switchWidth: 40px
$btnSize: 20px
$padding: 2px
.switcher
    display: none
    &__label
        position: relative
        box-sizing: border-box
        width: $switchWidth
        height: 24px
        padding: $padding
        box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08) inset
        border-radius: 16px
        background: linear-gradient(to right, #808697 50%, #4135AC 100%) 0 0
        background-size: (($switchWidth * 2) + 300px)
        transition: .3s ease
        &:hover
            cursor: pointer
        &::before
            content: ""
            width: $btnSize
            height: $btnSize
            position: absolute
            top: $padding
            left: $padding
            background-color: $white
            border-radius: 50%
            box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08)
            transition: left .3s ease, transform .1s ease
        &:active
            &::before
                transform: scale(0.9)
    &:checked
        & + .switcher__label
            background-position-x: -($switchWidth - 100px)
            &::before
                content: ""
                left: ($switchWidth - $btnSize - $padding)
