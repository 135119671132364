@import '../../assets/styles/_variables'
.btn
    position: relative
    padding: 10px 15px
    background-color: $violet800
    border: none
    cursor: pointer
    display: inline-block
    text-decoration: none
    &:hover
    //background-color: $blue
    &[disabled]
        //opacity: .5
        cursor: not-allowed
    &.active
// background-color: #23C9FF
// border: 1px solid #5EB2F2
// box-shadow: 0px 0 2px 0px #C0C0C0
