@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin
.area
  font-family: $Poppins
  background-color: rgba(0, 12, 47, .3)
  position: fixed
  display: flex
  align-items: center
  justify-content: center
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1001
.box
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  max-width: 539px
  width: 50%
  height: auto
  background-color: $white
  padding: 48px
  border-radius: 32px
  @include r(1050)
    width: 70%
  @include r(768)
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    max-width: none
    border-radius: 32px 32px 0 0
    padding: 60px 16px 32px
.close
  position: absolute
  width: 24px
  height: 24px
  top: 24px
  right: 24px
  cursor: pointer
  @include r(768)
    top: 36px
    right: 20px
.image
  width: 64px
  height: 64px
.title
  max-width: 85%
  text-align: center
  font-weight: 400
  font-size: 30px
  line-height: 40px
  margin-top: 24px
  margin-bottom: 8px
  color: $darkGrey900
.text
  max-width: 99%
  text-align: center
  font-weight: 400
  font-size: 16px
  line-height: 28px
  margin-bottom: 24px
  color: $darkgrey500
  @include r(768)
    margin-bottom: 40px
.btn
  width: 50%
  height: 50px
  background: $violet800
  border-radius: 16px
  color: $white
  font-weight: 600
  font-size: 16px
  line-height: 20px
  padding: 15px 20px
  @include r(768)
    width: 100%
.btn_area
  width: 100%
  display: flex
  justify-content: space-around
  align-items: center
  @include r(768)
    flex-direction: column-reverse
.btn_transparent
  color: $violet800
  border: 1.5px solid $violet800
  background: $white
  margin-right: 24px
  @include r(768)
    margin-right: 0
    margin-top: 32px
.imageSpin
  width: 64px
  height: 64px
  animation-name: spin
  animation-duration: 1800ms
  animation-iteration-count: infinite
  animation-timing-function: linear
@keyframes spin
  0% 
    transform: rotate(0deg)

  100% 
    transform: rotate(360deg)
