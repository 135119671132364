@import '../assets/styles/_variables'
@import '../assets/styles/_mixin'
.wrapper
    width: 586px
    padding: 32px
    background: $red800
    box-shadow: 0 4px 24px rgba(212, 209, 236, 0.3)
    border-radius: 32px
    position: fixed
    bottom: 40px
    right: 40px
    color: $white
    display: flex
    z-index: 200
    @include r(768)
        bottom: 66px
    @include r(680)
        width: calc(100% - 32px)
        margin: 0 16px
        right: 0
    @include r(490)
        display: block
        padding: 24px
.img
    width: 20px
    height: 24px
    margin-right: 28px
    margin-top: 4px
    @include r(490)
        margin: 0 0 16px
.container
    display: flex
    flex-direction: column
    .title
        font-family: $Poppins
        font-weight: 400
        font-size: 20px
        line-height: 30px
        margin-bottom: 10px
        @include r(490)
            margin-bottom: 6px
    .description
        font-family: $Poppins
        font-weight: 400
        font-size: 14px
        line-height: 20px
        margin-bottom: 24px
        @include r(490)
            margin-bottom: 16px
    .btns_area
        display: flex
        align-items: center
        @include r(380)
            justify-content: space-between
        @include r(360)
            font-size: 11px
    .main_btn
        background: $white
        border-radius: 16px
        color: $red800
        font-weight: 600
        font-size: 16px
        line-height: 20px
        padding: 10px 36px
        margin-right: 24px
        border: 0
        outline: 0
        @include r(490)
            padding: 10px 24px
        @include r(380)
            margin-right: 5px
        @include r(360)
            font-size: 14px
            padding: 10px 10px
        @include r(306)
            font-size: 12px
    .btn_transparent
        font-weight: 600
        font-size: 16px
        line-height: 20px
        @include r(360)
            font-size: 14px
        @include r(306)
            font-size: 12px
    a
        @extend .title
        margin-bottom: 0
        display: flex
        align-items: center
        color: $white
        text-decoration: none
.svg_close
    position: absolute
    top: 35px
    right: 32px
    width: 24px
    height: 24px
    cursor: pointer
    @include r(490)
        top: 24px
        right: 24px
.display_wrapper
    display: none