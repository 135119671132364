@import '../../assets/styles/_variables'
@import '../../assets/styles/_mixin'

.daterange
    font-family: $Poppins
    width: 100%
    position: relative
    //left: -20px
    @include r(768)
        left: 0
        width: 265px
        margin: 0 auto
        display: flex
        justify-content: center
    //.rdrMonths
    //    padding: 0 15px
    //    @include r(768)
    //        padding: 0
    .rdrMonth
        padding: 0 15px 0 3px
        width: 100%
        &:last-child
            padding: 0
        @include r(768)
            padding: 0
    .rdrDayDisabled
        background-color: transparent
        margin-top: 8px
        cursor: auto
    .rdrMonthAndYearPickers
        display: none
    .rdrMonthName
        text-align: center
        font-family: $Poppins
        font-size: 16px
        line-height: 28px
        font-weight: 400
        color: #000C2F
        padding: 0 0 16px
        box-shadow: inset 0px -1px 0px #F2F3F5
    .rdrNextPrevButton
        position: absolute
        top: -23px
    .rdrNextButton
        background-image: url('./next-arrow.svg')
        right: 0
    .rdrPprevButton
        background-image: url('./prev-arrow.svg')
    .rdrNextPrevButton
        margin: 0
        background-color: transparent
    .rdrMonthAndYearWrapper
        height: auto
        position: absolute
        top: 0
        left: 0
        width: calc(100% - 16px)
        margin: 25px 8px 0
        padding: 0 0 20px
        @include r(768)
            margin: 25px 12px 0
            padding: 0 0 16px
            width: calc(100% - 24px)
    .rdrDayToday .rdrDayNumber span
        border: 1px solid #2A1BA1
        border-radius: 24px
        width: 36px
        height: 36px
        position: relative
        left: 0
        padding-top: 3px
    .rdrDayToday .rdrDayNumber span:after
        display: none
    .rdrDay
        margin-top: 8px
        z-index: 2
    .rdrDay.rdrDayHovered
        z-index: 1
    .rdrDayNumber
        font-weight: 400
        font-size: 16px
        line-height: 28px
    .rdrMonth .rdrWeekDays
        padding-top: 10px
    .rdrWeekDay
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: #666D82
        padding-bottom: 8px
    .rdrDayNumber span
        color: #000C2F
        height: 36px
        padding: 2px 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 2
    .rdrDayDisabled .rdrDayNumber span
        color: #999EAC
    .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge
        top: 0
        height: 36px
        z-index: 2
    .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        left: -1px
        top: 0
    .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        right: -1px
        top: 0
    .rdrEndEdge
        border-top-right-radius: 24px
        border-bottom-right-radius: 24px
        right: 0
        top: 0
    .rdrStartEdge
        border-top-left-radius: 24px
        border-bottom-left-radius: 24px
        left: -1px
        top: 0
    .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview
        background: #F3F2FA
        border: none
        height: 36px
        z-index: 1
    .rdrDayStartPreview
        border-top-left-radius: 24px
        border-bottom-left-radius: 24px                     
    .rdrDayEndPreview, .rdrDayWeekend
        border-top-right-radius: 24px
        border-bottom-right-radius: 24px
    .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview
        top: 0
    .rdrDayStartOfWeek .rdrDayStartPreview, .rdrDayStartOfWeek .rdrStartEdge, .rdrDayStartOfMonth .rdrStartEdge
        border-top-left-radius: 24px
        border-bottom-left-radius: 24px
    .rdrDayEndOfWeek .rdrEndEdge, .rdrDayEndOfWeek .rdrDayEndPreview
        border-top-right-radius: 24px
        border-bottom-right-radius: 24px
    // .rdrDayEndOfWeek.rdrDayHovered .rdrDayStartPreview
    .rdrDayStartOfMonth .rdrDayEndPreview
        border-radius: 24px
    .rdrDayEndOfWeek .rdrDayInPreview,.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrStartEdge
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayInPreview
        border-top-left-radius: 0
        border-bottom-left-radius: 0
    .rdrDayPassive .rdrDayNumber span
        color: #999EAC
.daterange-home 
    @extend .daterange
    .rdrMonths
        width: 100%
        justify-content: space-between
    //    padding: 0
.daterange-host 
    @extend .daterange
    left: 0
    .rdrDayNumber span
        //color: $darkGrey900 !important
.calendar
    @extend .daterange
    font-family: $Poppins
    position: relative
    width: 280px
    left: -12px
    display: flex
    flex-direction: column
    align-items: center
    padding: 0 12px
    @include r(768)
        left: 0
        margin: 0 auto
        width: 265px
        //left: 50%
        //transform: translateX(-50%)
    .rdrNextPrevButton
        position: static    
    .rdrDay
        border-radius: 24px !important
        overflow: hidden
    .rdrMonth
        padding: 0
    .rdrDayDisabled
        background-color: transparent
    .rdrNextButton
        background-image: url('./next-arrow.svg')
    .rdrPprevButton
        background-image: url('./prev-arrow.svg')
    .rdrNextPrevButton
        background-color: transparent
    .rdrMonthAndYearPickers
        font-family: $Poppins
        font-size: 16px
        font-weight: 400
        color: #000C2F
    //.rdrMonths
    //    padding: 0
    .rdrMonth
        width: 248px
        @include r(768)
            width: 265px
    .rdrMonthAndYearPickers
        display: flex
    .rdrMonthAndYearWrapper
        height: 44px
        position: initial
        margin: 0 auto
        padding: 0 0 16px
        box-shadow: inset 0px -1px 0px #F2F3F5
        @include r(768)
            width: 100%
    .rdrDayNumber::after
        border: none !important
    .rdrDayHovered
        background: #F3F2FA
        border-radius: 36px
    .rdrDayHovered .rdrDayStartPreview
        border-radius: 24px !important
    .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge
        top: 0
        left: 0
        height: 36px
        width: 36px
        z-index: 2
    .rdrInRange
        color: transparent
    .rdrSelected
        border-radius: 24px
.rdrNextPrevButton i
    display: none
.monthly
    @extend .daterange
    width: 250px
    @include r(768)
        margin-left: auto
        margin-right: auto
        width: 265px
//.monthly .rdrMonths
//    padding: 0

.calendar-monthly 
    @extend .calendar
    width: 100%
    left: -10px
    .rdrMonthAndYearWrapper
        height: 1px
        padding: 0
        box-shadow: none
    .rdrMonthAndYearPickers
        display: none
    .rdrNextPrevButton
        position: absolute
        top: 4px
    .rdrMonth:first-child
        width: 247px
    .rdrMonth:last-child
        width: 256px
        margin-left: 15px
    .rdrMonthsHorizontal
        margin-left: 23px
.rdrDayDisabled .rdrInRange
    filter: grayscale(100%) opacity(0)
.rdrDayDisabled .rdrDayNumber span, .rdrDayPassive .rdrDayNumber, .rdrDayDisabled
    color: #999EAC !important
    text-decoration: line-through
.rdrDayDisabled .rdrDayInPreview
    background: transparent !important