@import '../../../components/assets/styles/variables'
@import '../../../components/assets/styles/mixin'
.camWrapp
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100vh
    background-color: rgba(0, 12, 47, 0.3)
    position: fixed
    top: 0
    left: 0
    z-index: 1001
    // @include r(940)
    //     height: 140%
    // @include r(768)
    //     height: 214%
//position: fixed
//opacity: 0.3
.cameraBox
    width: 100%
    max-width: 655px
    max-height: 100vh
    padding: 0 10px
    display: flex
    flex-direction: column
    background-color: rgba(255, 255, 255)
    border-radius: 32px
    margin: 0 auto
    overflow: auto
    // margin-top: 20px
    //position: absolute
    //top: 40%
    //left: 50%
    //transform: translate(-50%, -40%)
    //transform: translate(50%, 5%)
    //z-index: 1001
    @include r(768)
        max-width: 100%
        position: fixed
        left: 0
        bottom: 0
        transform: translate(0%, 0%)
        border-radius: 32px 32px 0px 0px
.headerBox
    display: flex
    width: 100%
    max-width: 573px
    justify-content: space-between
    border-bottom: 1px solid #CCCED5
    margin: 0 auto
    margin-bottom: 18px
    padding: 0 10px
    padding-top: 24px
.headerTitle
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 30px
    line-height: 40px
    color: #000C2F
    margin-bottom: 24px
.cameraWrapp
    display: flex
    flex-direction: column
    width: 100%
    max-width: 655px
    margin: 0 auto
.cameraAndBtnBox
    display: flex
    flex-direction: column
    width: 100%
    max-width: 573px
    height: 100%
    margin: 0 auto
    border-bottom: 1px solid #CCCED5
    margin-bottom: 24px
.cameraCont
    display: flex
    flex-direction: column
    border-radius: 32px
    width: 100%
    aspect-ratio: calc( 573px / 429.99px )
    @include r(600)
        width: 100%
        max-width: 655px
.frameWrap
    display: flex
    justify-content: center
    width: 100%
    max-width: 655px
    position: relative
    border-radius: 32px
    height: 100%
    max-height: 430px
    @include r(600)
        width: 100%
        max-width: 655px
.modalFrame
    position: absolute
    padding: 20% 30%
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    border: 6px solid #F2F3F5
    border-radius: 24px
    z-index: 40
.subTitle
    text-align: center
    font-family: $Poppins
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 28px
    color: #666D82
    margin-bottom: 16px
    margin-top: 16px
.btnBox
    display: flex
    width: 100%
    max-width: 374px
    justify-content: space-between
    margin: 0 auto
    margin-bottom: 24px
    @include r(768)
        flex-direction: column
.btnCap
    align-items: center
    padding: 10px 25px
    width: 175px
    height: 40px
    border: none
    background-color: #2A1BA1
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: #ffffff
    @include r(768)
        margin-bottom: 24px
        width: 100%
.disabledPhoto
    padding: 10px 25px
    width: 175px
    height: 40px
    border: none
    background-color: #666D82
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: #ffffff
    pointer-events: none
    @include r(768)
        margin-bottom: 24px
        width: 100%
.btnRef
    padding: 10px 35px
    width: 175px
    height: 40px
    background-color: #ffffff
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: #2A1BA1
    border: 1.5px solid #2A1BA1
    @include r(768)
        margin-bottom: 0
        width: 100%
.applyBtnBox
    display: flex
    justify-content: flex-end
    width: 100%
    max-width: 655px
    align-items: flex-end
    margin-bottom: 24px
    @include r(768)
        max-width: 374px
        margin: 0 auto
.btnApply
    padding: 16px 48px
    width: 144px
    height: 52px
    background-color: #2A1BA1
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: #ffffff
    border: none
    @include r(768)
        margin-bottom: 24px
        width: 100%
.disabled
    padding: 16px 48px
    width: 144px
    height: 52px
    background-color: #666D82
    border-radius: 16px
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: #ffffff
    border: none
    pointer-events: none
    @include r(768)
        margin-bottom: 24px
        width: 100%
//--------------------------screenshot image-----------------
//.canvas
//    width: 97%
//    height: 97%
//    max-width: 795px
//    max-height: 534px
//    position: absolute
//    top: 44%
//    left: 50%
//    transform: translate(-50%, -50%)
//    border-radius: 32px
//    z-index: 50
.canvas
    display: flex
    margin: 0 auto
    width: 97%
    height: 97%
    max-width: 655px
    max-height: 428px
    border-radius: 32px
.canvasImage
    object-fit: cover
    border-radius: 32px
    width: 100%
    max-width: 655px
    height: 100%
    max-height: 428px
    @include r(768)
        max-height: 266px
