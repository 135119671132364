@import '../assets/styles/_variables'
@import '../assets/styles/_mixin'

.DefaultProgressBar__vertical 
  width: 24px
  height: 100%

.DefaultProgressBar_progressBar 
  background-color: #666D82
  position: absolute

.DefaultProgressBar_progressBar__vertical 
  height: 100%
  width: 24px

.DefaultProgressBar_background__vertical 
  height: 100%
  top: 0px
  width: 15px

.DefaultProgressBar_background__horizontal 
  height: 6px
  top: -18px

.DefaultHandle_handle 
  width: 24px
  height: 24px
  border-width: 2px
  border-style: solid
  border-color: #333D58
  background-color: #fcfcfc
  border-radius: 50%
  outline: none
  z-index: 2
  box-shadow: 0 2px 2px #dbdbdb

.DefaultHandle_handle:focus 
  box-shadow: #abc4e8 0 0 1px 1px

.DefaultHandle_handle:after 
  content: url('./lines.svg')
  display: block
  position: absolute
  top: 2px
  left: 5px

.DefaultHandle_handle__horizontal 
  margin-left: -12px
  top: -27px




.DefaultHandle_handle__vertical 
  margin-top: -12px
  left: -10px



.DefaultHandle_handle__vertical:after 
  top: 13px
  left: 8px
  height: 1px
  width: 10px

.DefaultHandle_handle__disabled 
  border-color: #dbdbdb

.DefaultBackground 
  background-color: #d8d8d8
  height: 15px
  width: 100%
  border: 1px solid #d8d8d8
  position: relative

.DefaultBackground_background__horizontal 
  height: 6px
  left: -2px
  bottom: 17px
  width: 100%

.DefaultBackground_background__vertical 
  width: 15px
  top: 0px
  height: 100%

.rheostat 
  position: relative
  top: 48px
  left: 10px
  width: 406px
  overflow: visible
  @include r(990)
    width: 80%
    left: 10%
  @include r(550)
    width: 98%
    left: 0

.rheostat-square
  .rheostat
    width: 780px
    top: 24px
    @include r(990)
      left: 0
    @include r(843)
      width: 98%