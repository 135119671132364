@import '../assets/styles/_variables'

.error
    font-family: $Poppins
    display: flex 
    align-items: center 
    justify-content: center
    height: 100vh
    flex-direction: column
    padding-left: 16px
    padding-right: 16px
.errorTitle
    font-size: 24px
    margin-bottom: 24px
    line-height: 32px
    text-align: center
.button
    display: block
    font-size: 16px
    font-family: $PoppinsSemiBold
    background-color: #2E1CC4
    color: #ffffff
    border: 0
    border-radius: 16px
    padding: 8px 16px
