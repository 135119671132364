@import '../assets/styles/_variables'
@import '../assets/styles/_mixin'
.wrapper
    position: relative
.price__button
    position: relative
    color: #000C2F
    height: 40px
    padding-left: 24px
    padding-right: 24px
    border: 2px solid #000C2F
    border-radius: 16px
    font-size: 16px
    line-height: 20px
    background: transparent
    outline: none
    font-family: $Poppins
    margin-right: 16px
    white-space: nowrap
    transition: 0.25s all ease
    &:hover,
    &.active
        background-color: #2A1BA1
        color: #FFFFFF
        border: 2px solid #2A1BA1
    @include r(550)
        width: 40px
        height: 40px
        border-radius: 50%
        padding-left: 0
        padding-right: 0
        display: flex
        justify-content: center
        align-items: center
.indicator
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    width: 12px
    height: 12px
    border-radius: 50%
    background-color: #FFFFFF
    top: 0
    right: -2px
.inner
    width: 8px
    height: 8px
    border-radius: 50%
    background-color: #DA5449
.title
    font-family: $PoppinsSemiBold
    font-size: 18px
    line-height: 28px
    color: $darkGrey900
    margin-left: auto
    margin-right: auto
.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 5, 18, 0.3)
    z-index: 21
    display: none
    @include r(990)
        display: block
.dropdown
    width: 652px
    border-radius: 16px
    padding: 24px
    box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08)
    background-color: #FFFFFF
    position: absolute
    top: 60px
    left: 0
    z-index: 10
    display: flex
    flex-direction: column
    @include r(990)
        position: fixed
        top: auto
        bottom: 0
        left: 0
        right: 0
        width: 100%
        max-height: calc(100vh - 6px)
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
        border-top-left-radius: 32px
        border-top-right-radius: 32px
        z-index: 1001
        padding-top: 40px
        overflow-y: scroll
    
.flex
    display: flex
    padding-bottom: 24px
    @include r(990)
        flex-direction: column
.text
    font-family: $Poppins
    color: #808697
    font-size: 14px
    line-height: 20px
    max-width: 202px
    @include r(550)
        max-width: 100%
.footer
    display: flex
    align-items: center
    justify-content: space-between
    border-top: 1px solid #F2F3F5
    padding-top: 24px
    @include r(550)
        flex-direction: column
        align-items: flex-start
.rheostat__wrapper
    width: 75%
    padding-right: 10px
    padding-left: 10px
    border-right: 1px solid #F2F3F5
    @include r(990)
        border: 0
        width: 100%
.price__inputs
    position: relative
    top: 64px
    width: 420px
    display: flex
    justify-content: space-between
    @include r(990)
        margin-left: auto
        margin-right: auto
    @include r(550)
        flex-direction: column
        width: 100%
.input
    width: 198px
    height: 52px
    border-radius: 16px
    border: 1px solid #666D82
    color: #666D82
    font-size: 16px
    padding-left: 24px
    @include r(550)
        width: 100%
.topInput
    margin-bottom: 24px
.right
    padding-left: 20px
    padding-top: 4px
    width: 25%
    @include r(990)
        display: flex
        padding-top: 72px
        padding-left: 0
        max-width: 330px
        width: 100%
        margin: 0 auto
    .btn
        font-family: $Poppins
        font-size: 14px
        line-height: 24px
        color: #000C2F
        padding: 6px 8px
        margin-bottom: 4px
        padding-left: 16px
        max-width: 200px
        background: transparent
        @include r(990)
            padding-left: 16px
            padding-right: 16px
            margin-bottom: 0
            white-space: nowrap
        @include r(375)
            font-size: 3.6vw
            padding-left: 4vw
            padding-right: 4vw
        &:hover,
        &.active
            background: #F3F2FA
            border-radius: 48px
            color: #2A1BA1
            cursor: pointer
            font-weight: 600
.buttons__wrapper
    display: flex
    @include r(550)
        flex-direction: column
        width: 100%
.clear
    height: 40px
    width: 160px
    display: flex
    justify-content: center
    align-items: center
    border: 2px solid #CCCED5
    border-radius: 16px
    color: #000C2F
    font-family: $PoppinsSemiBold
    font-size: 16px
    margin-right: 24px
    background: transparent
    @include r(550)
        margin-top: 16px
        width: 100%
.apply
    height: 40px
    width: 160px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 16px
    color: #FFFFFF
    background-color: #2A1BA1
    font-family: $PoppinsSemiBold
    font-size: 16px
    border: 0
    @include r(550)
        margin-top: 24px
        width: 100%
.input__wrapper
    position: relative
    span
        position: absolute
        top: 18px
        left: 16px
        font-size: 16px
        color: #666D82
        font-family: $Poppins
.label
    position: absolute
    font-family: $Poppins
    font-size: 12px
    padding-left: 4px
    padding-right: 4px
    color: #666D82
    background: #FFFFFF
    top: -6px
    left: 16px
.closeBtn
    position: absolute
    top: 24px
    right: 24px
    border: 0
    background: transparent
    display: none
    @include r(990)
        display: block
.disabled
    background-color: #666D82

