@import '../../assets/styles/_variables'
.wrapper
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 12, 47, 0.2)
    z-index: 202
.image
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 203
