@import '../../../../../components/assets/styles/variables'
@import '../../../../../components/assets/styles/mixin'

.row
  display: flex
  justify-content: space-between
  color: $darkGrey900
  font-size: 16px
  font-family: $PoppinsSemiBold
  margin-bottom: 16px
.quantityWrapper
  position: relative
  margin-top: 24px
.quantityInput
  width: 100%
  height: 52px
  border-radius: 16px
  border: 1.5px solid #666D82
  text-align: center
.quantityLabel
  position: absolute
  color: #666D82
  font-size: 12px
  font-family: $Poppins
  background-color: #ffffff
  padding-left: 4px
  padding-right: 4px
  top: -6px
  left: 16px
.btn 
  position: absolute
  border: 0
  background: transparent
  z-index: 10
  top: 16px
.btnDecrement
  left: 16px
.btnIncrement
  right: 16px

