@import '../../assets/styles/_variables'
@import '../../assets/styles/_mixin'
.container
    display: flex
    flex-wrap: wrap
.wrapper
    border: 1px dashed #808697
    box-sizing: border-box
    padding-top: 160px
    padding-bottom: 160px
    border-radius: 16px
    height: 12.81vmax
    width: 100%
    position: relative
    //overflow: hidden
    display: flex
    @include r(1500)
        height: 15.81vmax
    @include r(1300)
        height: 20.81vmax
    @include r(1200)
        height: 22.81vmax
    @include r(990)
        height: 260px
        padding-top: 0
        padding-bottom: 0
    @include r(768)
        height: 160px
    &_small
        width: calc(50% - 8px)
        height: 170px
        padding-top: 0
        padding-bottom: 0
        margin-bottom: 18px
        @include r(768)
            height: 120px
            width: 100%
        @include r(550)
            width: 100%
            height: 90px
.label_edit
    width: calc(25% - 8px)
    @include r(768)
        width: 100%
        height: 140px
label.label
    display: flex
    flex-direction: column
    position: absolute
    justify-content: center
    align-items: center
    top: 0
    left: 0
    bottom: 0
    right: 0
    padding: 0px
    cursor: pointer
    @include r(768)
        padding: 16px
    img
        margin-bottom: 16px
.label__description
    font-size: 20px
    line-height: 30px
    color: #1E1E1E
    font-family: $Poppins
    margin-bottom: 8px
    // display: flex
    // flex-wrap: wrap
    // justify-content: center
    & > b
        color: $violet100
        margin-left: 4px
.label__small
    display: none
.textCenter
    text-align: center
.subtitle
    max-width: 295px
    color: #666D82
    text-align: center
    &_small
        display: none
.choose
    display: flex
    align-items: center
    justify-content: center
    background: transparent
    outline: none
    margin-top: 16px
    margin-bottom: 16px
    font-family: $PoppinsSemiBold
    height: 40px
    width: 160px
    border-radius: 16px
    border: 1px solid #2A1BA1
    font-size: 16px
    color: #2A1BA1
    &_small
        display: none
input.input
    display: none
.image
    width: 100%
    height: 100%
    border-radius: 16px
    object-fit: cover
    &_margin
        margin-right: 30px
    &__container
        width: calc(100%)
        height: 12.81vmax
        border-radius: 16px
        margin-bottom: 10px
        position: relative
        &:nth-child(odd)
            margin-left: 15px
        &:nth-child(even)
            margin-right: 15px
.hero
    border-radius: 16px
    height: 12.81vmax
    width: 100%
    margin-bottom: 10px
    position: relative
    object-fit: cover
    @include r(1500)
        height: 15.81vmax
    @include r(1300)
        height: 20.81vmax
    @include r(1200)
        height: 22.81vmax
    @include r(920)
        height: 24.81vmax
    @include r(768)
        height: 26.81vmax
.deleteButton
    padding: 0
    width: 40px
    height: 40px
    border-radius: 50%
    background-color: $white
    border: none
    z-index: 1
    position: absolute
    top: 16px
    right: 16px
    display: flex
    align-items: center
    justify-content: center
    color: $darkGrey900
    &:hover
        cursor: pointer
    @include r(425)
        top: 10px
        right: 10px
.coverButton
    padding: 0
    width: 134px
    height: 36px
    border-radius: 100px
    background-color: $white
    border: 1px solid #1A2444
    z-index: 1
    position: absolute
    top: 16px
    left: 16px
    display: flex
    align-items: center
    justify-content: center
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: $darkGrey900
    &:hover
        cursor: pointer
    @include r(425)
        top: 10px
        left: 10px
.error
    font-family: $PoppinsHeavy
    font-style: normal
    font-size: 1.4rem
    line-height: 2.0rem
    color: white
    margin-bottom: 24px
    text-align: center
    background: red
.errorMessage
    font-size: 12px
    line-height: 16px
    position: absolute
    color: rgb(209, 41, 27)
.photoError
    border: 1.5px solid rgb(209, 41, 27)
.top__wrapper
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    margin-bottom: 16px
    @include r(550)
        flex-direction: column
        .choose
            width: 100%
.imageMenu
    width: 200px
    box-shadow: 4px 0px 24px rgba(212, 209, 236, 0.3)
    position: absolute
    background: #FFFFFF
    top: 64px
    right: -140px
    z-index: 1000
    border-radius: 16px
    padding: 16px 24px
    @include r(1670)
        right: 8px
    @include r(768)
        position: fixed
        bottom: 0
        left: 0
        right: 0
        top: auto
        width: 100%
        height: 138px
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
.imageBtn
    width: 100%
    text-align: left
    border: 0
    background: transparent
    outline: none
    display: block
    font-family: $Poppins
    font-size: 14px
    color: #000C2F
    padding-top: 4px
    padding-bottom: 4px
    white-space: nowrap
    &:first-child
        margin-bottom: 16px
.top__margin
    margin-top: 80px
    @include r(768)
        margin-top: 0
.filled
    color: #000C2F
.filled__subtitle
    color: #000C2F
    font-size: 14px
    text-align: left
    @include r(550)
        margin-bottom: 16px
.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 5, 18, 0.3)
    z-index: 21
    display: none
    @include r(768)
        display: block
.photoWrapper
    position: relative
    width: calc(50% - 8px)
    &:nth-child(odd)
        margin-right: 16px
    @include r(768)
        width: 100%
        height: 236px
        &:nth-child(odd)
            margin-right: 0
    margin-right: 0
    margin-bottom: 8px
.quater
    width: calc(25% - 8px)
    &:nth-child(odd)
        margin-right: 8px
    margin-right: 8px
    @include r(768)
        width: 100%
        height: 236px
        &:nth-child(odd)
            margin-right: 0
        margin-right: 0
        margin-bottom: 8px
.photoWrapperFull
    width: 100%
.closeVerificationImage
    position: absolute
    top: 0
    background: white
    right: 0
.verificationImagesWrap
    display: flex
    flex-direction: column
    position: absolute
    justify-content: center
    align-items: center
    top: 0
    left: 0
    bottom: 0
    right: 0
    padding: 24px
    cursor: pointer
    &__images
        flex-direction: row
        align-items: baseline
        justify-content: flex-start
    @include r(768)
        padding: 16px
.verificationImg
    height: 64px
    width: 100px
    margin-right: 16px
.miniPicCloseBox
    position: relative
    margin: 16px
    &__blur
        filter: blur(2px)
.close
    width: 40px
    height: 40px
    background: white
    border-radius: 50%
    box-shadow: -2px 8px 20px #d4d1ec
    position: absolute
    top: 0
    left: 76px
    padding: 8px
    z-index: 100
    &:hover
        cursor: pointer
.imaageWrapper
    border: 1px dashed #808697
    box-sizing: border-box
    border-radius: 16px
    width: 100%
    position: relative
    min-height: 254px
.imaageWrapperSpace
    padding: 48px 0 32px
.imageFlex
    display: flex
    flex-wrap: wrap
    justify-content: start
.intoLabel
    display: flex
    flex-direction: column
    align-items: center
