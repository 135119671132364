$violet100: #2e1cc4
$dark100: #515970
$gray100: #515970
$white: #ffffff
$whiteLight: #FEFFFF
$cardStile: #ffffff
$orrange: #F87B08
$header-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16)
$dark20: #CCCED5
$gray10: #EEEEF1
$grayBackgroundBody: #E5E5E5
$blue: #308FFF
$violet: #7B4CFF
$green: #06E7B1
$pink: #FF4D97
$yellow: #FFBF42
$red: #EA4335
$darkGrey900: #000C2F
$darkGrey800: #1A2444
$darkgrey500: #666D82
$darkgrey400: #808697
$violet800: #2A1BA1
$red800: #D63F32
$red900: #D1291B
// FONT

$Poppins: 'Poppins', sans-serif
$PoppinsSemiBold: 'PoppinsSemiBold', sans-serif
$PoppinsLight: 'PoppinsLight', sans-serif
$PoppinsBold: 'PoppinsBold', sans-serif
$PoppinsHeavy: 'PoppinsHeavy', sans-serif
$PoppinsMedium: 'PoppinsMedium', sans-serif
