@import '../../../components/assets/styles/variables'
@import '../../../components/assets/styles/mixin'
.input
    width: 24px
    height: 24px
.inputCheked
    width: 14px
    height: 14px
    background-color: #2E1CC4
    border-radius: 50px
.container
    display: flex
    margin-right: 53px
    position: relative
.wrapper
    box-sizing: border-box
    border-radius: 50px
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    cursor: pointer
    margin-right: 12px
    border: 2px solid #666D82
.wrapperCheked
    box-sizing: border-box
    border-radius: 50px
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    cursor: pointer
    margin-right: 12px
    border: 2px solid #2E1CC4
.notChecked
    background: #FFFFFF
    border: 2px solid #666D82
.checked
    border: 2px solid #2E1CC4
.custom
    display: none
input, textarea
    font-size: 16px
.label
    font-family: $Poppins
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: #666D82
.inputSvg
    position: absolute
    top: 0
    left: 0
//-----------------radio------------------------------------------------------------
.radioWrapper
    display: flex
    align-items: center
    font-size: 16px
    font-family: $PoppinsSemiBold
    color: #666D82
    margin-right: 48px
    @include r(475)
        margin-right: 13px
    &:hover
        cursor: pointer
.radioCheck
    display: flex
    justify-content: center
    align-items: center
    position: relative
    width: 24px
    height: 24px
    border: 2px solid #666D82
    border-radius: 50%
    margin-right: 12px
.radioChecked
    border: 2px solid #2A1BA1
    &:after
        content: ''
        position: absolute
        top: 2px
        left: 2px
        display: block
        width: 16px
        height: 16px
        border-radius: 50%
        z-index: 2
        background-color: #2A1BA1
.labelChecked
    color: #2A1BA1
