@import '../assets/styles/_variables'
@import ../assets/styles/_mixin
\:root
    --animation-time-footer: 0s
.footer
    width: 100%
    font-family: $Poppins
    position: fixed
    bottom: 0
    left: 0
    background-color: $white
    border-radius: 32px 32px 0 0
    box-shadow: 0 4px 24px rgba(212, 209, 236, 0.3)
    z-index: 111
    @include r(990)
        border-radius: 0
        box-shadow: none
        position: initial
.wrapper
    max-width: 1120px
    padding: 16px 10px
    margin: 0 auto
    @include r(990)
        padding: 0
.mini_footer
    width: 100%
    display: flex
    justify-content: space-between
    @include r(990)
        display: none
.mini_footer_text_area
    display: flex
    align-items: center
.span
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $darkgrey500
.mini_footer_ul
    display: flex
    align-items: center
.mini_footer_li
    padding-left: 12px
    display: flex
    align-items: center
.mini_footer_a
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $darkGrey900
    padding-left: 12px
.mini_footer_support
    display: flex
    align-items: center
    cursor: pointer
.mini_footer_support p
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: $darkGrey900
    margin-right: 12px
.big_footer_bg
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1
    @include r(990)
        display: block
        position: relative
        top: 0
        left: 0
.big_footer_bg_show
    display: block
    animation: start 0.5s ease forwards
    @include r(990)
        animation: none
.big_footer_bg_white
    position: absolute
    left: 0
    animation: open 0.5s ease-in-out forwards
    width: 100%
    background-color: $whiteLight
    border-radius: 32px 32px 0 0
    z-index: 110
    @include r(990)
        animation: none
        border-radius: 0
.big_footer_bg_close
    pointer-events: none
    animation: end var(--animation-time-footer) ease-in-out forwards
    @include r(990)
        pointer-events: auto
        animation: none
    .big_footer_bg_white
        animation: close var(--animation-time-footer) ease-in-out forwards
        @include r(990)
            animation: none
.footer_close
    position: absolute
    top: 24px
    right: 24px
    width: 24px
    height: 24px
    cursor: pointer
    @include r(1223)
        top: 18px
        right: 18px
        width: 18px
        height: 18px
    @include r(990)
        display: none
.big_footer
    max-width: 1120px
    padding: 40px 8px
    margin: 0 auto
    display: grid
    grid-template-columns: minmax(auto, 255px) 1fr 160px 1fr 160px 1fr 265px
    grid-template-areas: "logo . company . resourses . newsletter" "contacts . company . resourses . newsletter"
    @include r(1143)
        padding: 40px 16px
    @include r(990)
        max-width: 540px
        padding: 0
        margin-bottom: 8px
        grid-template-columns: 1fr
        grid-template-areas: "logo" "newsletter" "company" "resourses" "contacts"
.logo
    grid-area: logo
    margin-bottom: 24px
    width: 165px
    height: 44px
    cursor: pointer
.contacts
    grid-area: contacts
.p
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: $darkgrey500
    margin-bottom: 8px
.social_media
    margin: 18px 0
.social_media_link
    margin-right: 16px
.social_media_link:hover
    opacity: .7
.company
    grid-area: company
    @include r(990)
        margin-bottom: 24px
.footer_title
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: $darkgrey500
    margin-bottom: 16px
.footer_li
    margin-bottom: 8px
.footer_a
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $darkGrey900
.footer_a:hover
    opacity: .5
.resources
    grid-area: resourses
    @include r(990)
        margin-bottom: 24px
.newsletter
    grid-area: newsletter
    @include r(990)
        margin-bottom: 4px
.newsletter_title
    font-weight: 600
    font-size: 18px
    line-height: 18px
    color: $darkgrey500
    margin-bottom: 27px
.form
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
.inputArea
    display: flex
    width: 100%
.input input
    min-width: 256px
    height: 52px
    border: 1.5px solid $darkgrey500
    margin-right: 58px
    @include r(1280)
        min-width: auto
        margin-right: 8px
.submitButton
    display: flex
    align-items: center
    position: absolute
    top: 0
    right: -58px
    width: 50px
    height: 50px
    background-color: $violet800
    box-shadow: -2px 8px 20px #D4D1EC
    border-radius: 32px
    padding: 20px 13px
    @include r(1280)
        position: initial
        margin-left: 8px
    @include r(990)
        margin-left: 16px
    &:hover
        background-color: #308FFF
    &:disabled
        background-color: $darkgrey500
        opacity: 1
.successSubmitting
    min-width: calc(100% - 66px)
    position: relative
    left: 0
    top: -15px
    color: #120297
    display: flex
    justify-content: flex-end
    font-weight: 400
    font-size: 12px
    line-height: 16px
    width: 100%
    @include r(1280)
        justify-content: flex-start
    @include r(990)
        justify-content: flex-end
        padding-right: 66px
    @include r(360)
        justify-content: flex-start
        padding-right: 0
@keyframes open
    0%
        bottom: -300px
    100%
        bottom: 0
@keyframes close
    0%
        bottom: 0
    100%
        bottom: -300px
@keyframes start
    0%
        background-color: rgba(0, 0, 0, 0)
    100%
        background: rgba(0, 12, 47, .3)
@keyframes end
    0%
        background: rgba(0, 12, 47, .3)
    100%
        background-color: rgba(0, 0, 0, 0)
.avoidBottomNav
    @include r(768)
        padding-bottom: 57px