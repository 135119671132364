@import '../assets/styles/_variables'
@import '../assets/styles/_mixin'

.button
    position: relative
    display: flex
    align-items: center
    font-family: $Poppins
    color: #000C2F
    border: 2px solid #000C2F
    font-size: 16px
    height: 40px
    padding-left: 24px
    padding-right: 24px
    border-radius: 16px
    background: transparent
    white-space: nowrap
    transition: 0.25s all ease
    &:hover
        background-color: #2A1BA1
        color: #FFFFFF
        border: 2px solid #2A1BA1
    @include r(550)
        width: 40px
        height: 40px
        border-radius: 50%
        padding-left: 0
        padding-right: 0
        display: flex
        justify-content: center
        align-items: center
.indicator
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    width: 12px
    height: 12px
    border-radius: 50%
    background-color: #FFFFFF   
    top: 0
    right: -2px
.inner
    width: 8px
    height: 8px
    border-radius: 50%
    background-color: #DA5449 
.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 5, 18, 0.3)
    z-index: 2000
    display: block
    overflow-y: scroll
.wrapper
    width: 843px
    border-radius: 32px
    background-color: #FFFFFF
    position: absolute
    left: 50%
    top: 24px
    margin-bottom: 24px
    transform: translateX(-50%)
    padding: 24px
    z-index: 1002
    @include r(843)
        width: 100%
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
.closeBtn
    position: absolute
    top: 24px
    right: 24px
    border: 0
    background: transparent
.titleWrapper
    border-bottom: 1px solid #CCCED5
.title
    font-size: 30px
    font-family: $Poppins
    color: $darkGrey900
    margin-bottom: 24px
    line-height: 40px
.block
    padding-top: 24px
    padding-bottom: 24px
    border-bottom: 1px solid #CCCED5
.blockTitle
    font-size: 18px
    line-height: 30px
    font-family: $PoppinsSemiBold
    margin-bottom: 16px
.btnWrapper
    display: flex
    padding-top: 24px
    justify-content: flex-end
    @include r(550)
        flex-direction: column
.clear
    width: 144px
    height: 50px
    border: 1px solid #CCCED5
    background: transparent
    border-radius: 16px
    font-size: 16px
    font-family: $PoppinsSemiBold
    display: flex
    justify-content: center
    align-items: center
    color: $darkGrey900
    cursor: pointer
    @include r(550)
        width: 100%
        margin-bottom: 24px

.apply
    width: 144px
    height: 50px
    border: 1px solid #CCCED5
    border-radius: 16px
    font-size: 16px
    font-family: $PoppinsSemiBold
    display: flex
    justify-content: center
    align-items: center
    margin-left: 30px
    color: #FFFFFF
    background: #666D82
    @include r(550)
        width: 100%
        margin-left: 0
.applyActive
    color: #ffffff
    background-color: #2A1BA1
.inputWrapper
    width: 320px
.image
    margin-right: 8px
.itemsWrapper
    display: flex
    flex-wrap: wrap
.item
    display: flex
    align-items: center
    font-family: $PoppinsSemiBold
    font-size: 16px
    color: #1A2444
    padding: 8px 16px
    border-radius: 100px
    width: fit-content
    border: 1px solid #CCCED5
    margin-right: 10px
    margin-bottom: 10px
    @include r(425)
        font-size: 12px
        padding: 6px 12px
    &:hover
        cursor: pointer
        color: #2A1BA1
        border: 1px solid #2A1BA1
        .icon
            color: #2A1BA1

.icon
    color: #1A2444
.itemActive
    background: #2A1BA1
    border: 1px solid #2A1BA1
    color: #ffffff
    .icon
        color: #ffffff
    &:hover
        cursor: pointer
        color: #FFFFFF 
        .icon
            color: #ffffff
.blockSubtitle
    font-size: 16px
    line-height: 28px
    color: $darkGrey900
    font-family: $Poppins
    margin-bottom: 16px
.topMargin
    margin-top: 8px
.price__inputs
    position: relative
    top: 40px
    padding-bottom: 48px
    width: 100%
    display: flex
    justify-content: space-between
    @include r(768)
        flex-direction: column
.input
    width: 386px
    height: 52px
    border-radius: 16px
    border: 1px solid #666D82
    color: #666D82
    font-size: 16px
    padding-left: 24px 
    @include r(880)
        width: 100%
    @include r(550)
        width: 100%
.topInput
    @include r(768)
        margin-bottom: 24px
.input__wrapper
    position: relative
    @include r(880)
        width: 48%
    @include r(550)
        width: 100%
    span
        position: absolute
        top: 18px
        left: 16px
        font-size: 16px
        color: #666D82
        font-family: $Poppins
.label
    position: absolute
    font-family: $Poppins
    font-size: 12px
    padding-left: 4px
    padding-right: 4px
    color: #666D82
    background: #FFFFFF
    top: -6px
    left: 16px