@font-face
    font-family: 'PoppinsBold'
    // src: url('../fonts/Poppins-Bold.wof') format('wof')
    src: url('../fonts/Poppins-Bold.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
@font-face
    font-family: 'PoppinsHeavy'
    src: url('../fonts/Poppins-Medium.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
@font-face
    font-family: 'Poppins'
    src: url('../fonts/Poppins-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
@font-face
    font-family: 'PoppinsSemiBold'
    src: url('../fonts/Poppins-SemiBold.woff') format('woff')
    src: url('../fonts/Poppins-SemiBold.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
@font-face
    font-family: 'PoppinsLight'
    src: url('../fonts/Poppins-Light.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
@font-face
    font-family: 'PoppinsMedium'
    src: url('../fonts/Poppins-Medium.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

