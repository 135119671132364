@import '../../../assets/styles/_variables'
@import '../../../assets/styles/_mixin'
%default
    //border: 1px solid red
    //width: 100%
    //max-width: 540px
    height: 52px
    background: $white
    border: 1.5px solid #666D82
    box-sizing: border-box
    border-radius: 16px
    box-shadow: none
    padding: 6px 24px
    cursor: pointer
    @include r(768)
       padding: 6px 16px 
    &:hover
        border: 1.5px solid #000C2F
.active
    @extend %default
    border: 1.5px solid #000C2F
//border: none
.filled
    .select__control
        border: 1.5px solid #000C2F
.hasError
    .select__control
        border: 1.5px solid $red900
        &:hover
            border: 1.5px solid $red900
div.select
    width: 100%
    &__container
        position: relative
        span.select__label
            padding: 0 8px
            background-color: $white
            position: absolute
            top: -6px
            left: 16px
            font-family: $Poppins
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 16px
            color: #666D82
            z-index: 10
            &.filled
                color: #000C2F
            &.error
                color: $red900
    &__control
        @extend %default
        @include r(768)
    //margin-bottom: 47px
    &__value-container
        padding: 2px 0
        height: 100%
        margin: 0
    &__placeholder
        font-family: $Poppins
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 16px
        color: #666D82
        margin: 0
    &__single-value
        font-family: $Poppins
        font-style: normal
        font-weight: 400
        font-size: 16px
        line-height: 28px
        color: #000C2F
        margin-left: -1px
    &__menu
        background: $white
        border-radius: 24px
        padding: 16px 8px
        box-shadow: 0px 4px 24px rgba(99, 101, 109, 0.08)
        z-index: 30
    &__menu-list
        &::-webkit-scrollbar-track
            border-radius: 10px
            background-color: #ffffff
        &::-webkit-scrollbar
            width: 6px
            background-color: #F5F5F5
        &::-webkit-scrollbar-thumb
            border-radius: 10px
            background-color: $violet100
    &__option
        font-family: $Poppins
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 20px
        color: #000C2F
        margin-bottom: 8px
        border-radius: 24px
        cursor: pointer
        &--is-selected
            //background: $white
            font-family: $Poppins
            background: #F3F2FA
            border-radius: 48px
            font-weight: 600
            font-size: 14px
            line-height: 20px
            color: #2A1BA1
        &--is-focused
            background: #F3F2FA
        &:hover
            background: #F3F2FA
            border-radius: 48px
    //TODO:
    svg.select__icon
        color: $gray100
        transition: all 0.22s
        &--open
            transform: rotate(180deg)
.select__input
    font-family: $Poppins
    font-size: 1.6rem
    line-height: 2.4rem
    color: $dark100
.message
    text-align: right
    color: $red900
    margin: 5px 0
    font-size: 12px
