@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin
.root
    font-family: $Poppins
    font-style: normal
    font-weight: normal
    font-size: 1.6rem
    line-height: 2.8rem
    color: $darkGrey900
.heavy
    font-family: $PoppinsHeavy
    font-style: normal
    font-size: 1.6rem
    line-height: 2.4rem
.indented
    text-indent: 24px
    margin-bottom: 8px
.subtext
    @extend .root
    line-height: 2.4rem
    &__mini
        @extend .root
        line-height: 1.6rem
        font-size: 1.2rem
.light
    font-family: $PoppinsLight
.semiBold
    font-family: $PoppinsSemiBold
.title
    font-family: $Poppins
    font-size: 4.0rem
    line-height: 5.0rem
    color: $darkGrey900
    letter-spacing: -1px
    @include r(768)
        font-size: 3.0rem
        line-height: 4.0rem
    @include r(359)
        font-size: 2.8rem
        line-height: 3.8rem
