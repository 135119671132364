@import '../assets/styles/_variables'
@import '../assets/styles/_mixin'
header
    // box-shadow: $header-shadow //TODO:
    background-color: $white
    width: 100%
.headerHomePage
    padding: 0 16px
    background-color: #F4F7FE
.content
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    &__search
        @include r(768)
            justify-content: space-between
.wrapper
    padding: 32px 0
    width: 100%
    @include r(768)
        padding: 24px 0
.home__wrapper
    background-color: #F4F7FE
.header
    margin: 0 auto
    max-width: 1120px
    &__logo
        display: flex
        width: 165px
        height: 44px
        @include r(768)
            width: 150px
            height: 40px
        img
            width: 100%
            object-fit: contain
.nav
    display: flex
    position: relative
    align-items: center
    @include r(768)
        display: none
    &__item
        display: flex
        align-items: center
        margin-right: 4px
        padding: 6px 8px 6px 16px
        position: relative
        text-decoration: none
        transition: all 0.2s
        white-space: nowrap
        &__border
            @extend .host
        @include r(828)
            padding: 6px 2px 6px 8px
        span
            font-weight: 600
            font-size: 16px
            line-height: 20px
            color: $darkGrey900
        &_active
            span
                color: $violet800
            &::after
                content: ''
        &:hover
        //cursor: pointer
        &_adaptive
            @include r(768)
                display: none
.user
    &__avatar
        border: 1px solid $darkgrey500
        border-radius: 48px
        width: 40px
        height: 40px
        &__block
            width: 40px
            height: 40px
            margin-right: 12px
        &__letter
            @extend .user__avatar
            display: flex
            align-items: center
            justify-content: center
            font-size: 14px
            line-height: 20px
            font-family: $Poppins
            font-weight: 600
            color: $darkgrey500
            text-transform: uppercase
.host
    border: 1.5px solid $violet800
    border-radius: 16px
    margin: 0 16px
    padding: 10px 24px
    @include r(857)
        padding: 10px
    @include r(857)
        margin: 0
    span
        color: $violet800
//SearchHeaderComponent
.top_wrapper
    display: flex
    align-items: center
    justify-content: space-between
.search
    &__navigation
        @include r(768)
            display: none
    &__header
        background: $white
        padding: 20px 80px
        position: fixed
        top: 0
        left: 0
        right: 0
        z-index: 7
        height: 104px
        background-color: $white
        @include r(1700)
            padding: 24px 40px
        @include r(1500)
            padding: 24px 20px
        @include r(990)
            height: auto
            padding: 24px 20px 6px
            z-index: 9
        .logo
            max-width: 165px
            height: 44px
            &__container
                text-decoration: none
    &__component
        max-width: 575px
        width: 100%
        align-self: center
div.search__component
    @include r(963)
        margin: 0 auto
    @include r(769)
        display: none
form.form
    max-width: 575px
    height: 64px
    padding: 20px
    padding-right: 4px
    border-radius: 100px
    box-shadow: none
    border: 1px solid $gray10
button.search__button
    margin-right: 0
.select
    &__value
        font-family: $Poppins
        font-style: normal
        font-weight: normal
        font-size: 20px
        line-height: 24px
        color: $darkGrey900
    &__label
        display: none
    &__dropdown
        box-shadow: 0 4px 24px rgba(99, 101, 109, 0.08)
        border-radius: 24px
    &__separator
        height: 40px
        width: 1px
        background-color: #E8E8E8
    &__item
        display: flex
        width: calc((100% - 100px) / 2)
        &:last-child
            width: 102px
            & > div
                padding: 0
div.select__dropdown
    padding: 5px 0 20px
    width: 260px
    padding-left: 8px
    padding-right: 8px
    top: 46px
    left: -20px
    overflow: hidden
    &_options
        padding: 5px
    span
        font-size: 10px
        line-height: 14px
        padding: 10px
div.options
    font-size: 16px
    line-height: 32px
    padding: 0 5px
    margin-bottom: 4px
    padding-left: 16px
div.select__search
    &_container
        padding: 0 5px
        margin-bottom: 7px
        display: flex
        justify-content: center
        img
            margin-right: 0
            width: 20px
            height: 20px
        .input
            padding: 10px
            font-size: 10px
            line-height: 14px
            height: 34px
            & + div
                margin: 0
.burger
    height: 4px
    background-color: $dark100
    width: 100%
    border-radius: 12px
    position: absolute
    &_icon
    &__menu
        padding: 0
        background: none
        width: 20px
        height: 45px
        border: none
        align-items: center
        justify-content: center
        display: none
        position: relative
        //z-index: 1000
        @include r(768)
            display: flex
        &_search
            padding: 0
            background: none
            width: 24px
            height: 24px
            border: none
            align-items: center
            justify-content: center
            display: none
            @include r(768)
                display: flex
    &__search
        @include r(768)
            display: flex
            margin-left: auto
            z-index: 1000
    &:hover
        cursor: pointer
    &__wrapper
        width: 20px
        height: 24px
        position: relative
        transform: rotate(0deg)
        transition: .5s ease-in-out
        cursor: pointer
        span
            display: block
            position: absolute
            height: 2px
            width: 100%
            background: $darkgrey500
            border-radius: 9px
            opacity: 1
            left: 0
            transform: rotate(0deg)
            transition: .25s ease-in-out
            &:nth-child(1)
                top: 0px
                transform-origin: left center
            &:nth-child(2)
                top: 8px
                transform-origin: left center
            &:nth-child(3)
                top: 16px
                transform-origin: left center
        &.open
            span:nth-child(1)
                transform: rotate(45deg)
                top: 1px
                left: 4px
            span:nth-child(2)
                width: 0%
                opacity: 0
            span:nth-child(3)
                transform: rotate(-45deg)
                top: 15px
                left: 4px
    &_bottom
        width: 65%
        bottom: 0
        right: 0
    &_top
        top: 0
.signup
    span
        color: $violet800
    border: 1.5px solid $violet800
    padding: 10px 24px
    border-radius: 16px
.burger__bottom_animate
    animation: bottom 0.25s forwards, rotate_bottom 0.25s 0.25s forwards
.burger__top_animate
    animation: top 0.25s forwards, rotate_top 0.25s 0.25s forwards
.menu__title
    font-family: $Poppins
    font-size: 30px
    line-height: 40px
    color: $darkGrey900
    padding-top: 24px
    padding-bottom: 24px
.menu__btn
    border: 0
    outline: 0
    background: transparent
    text-align: left
    padding-left: 0
    padding-right: 0
.mobile
    &__menu
        justify-content: flex-end
        background-color: $white
        width: 100%
        height: 100%
        position: fixed
        left: 0
        top: 0
        z-index: 1000
        display: none
        @include r(768)
            overflow-y: scroll
            display: block
            transition: 0.2s all ease
            transform: translateY(-110%)
            top: -500px
            &.active
                transform: translateY(0)
                top: 0
        &_search
            @include r(768)
                display: block
                transition: 0.2s all ease
                transform: translateY(-110%)
                &.active
                    transform: translateY(0)
    &__item
        display: flex
        align-items: center
        width: 100%
        font-size: 18px
        line-height: 30px
        font-family: $PoppinsSemiBold
        color: $darkGrey900
        padding-top: 18px
        padding-bottom: 18px
        border-bottom: 1px solid #CCCED5
        &._active
            border: 0 !important
            height: auto
    &__signup
        font-size: 16px
        font-family: $PoppinsSemiBold
        color: $violet800
        border: 1.5px solid $violet800
        padding: 16px 36px
        border-radius: 20px
        margin-left: auto
    &__signin
        font-size: 16px
        font-family: $PoppinsSemiBold
        color: $darkGrey900
    &__host
        display: block
        margin-top: 32px
        padding: 15px 0
        text-align: center
        font-weight: 600
        font-size: 16px
        line-height: 20px
        width: 100%
        margin-right: 0
.menu__top
    background-color: #F4F7FE
    padding: 0 16px
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
.links__wrapper
    padding-top: 16px
    padding-left: 16px
    padding-right: 16px
@keyframes bottom
    0%
        width: 60%
        bottom: 0
    100%
        width: 100%
        bottom: calc(50% - 2px)
@keyframes top
    0%
        top: 0
    100%
        top: calc(50% - 2px)
@keyframes rotate_top
    0%
        transform: rotate(0)
    100%
        transform: rotate(-45deg)
@keyframes rotate_bottom
    0%
        transform: rotate(0)
    100%
        transform: rotate(45deg)
@keyframes animate-menu
    0%
        transform: translateY(-110%)
    100%
        transform: translateY(0)
.search__block
    padding: 12px 0
    border-bottom: 1px solid #F2F3F5
.city
    font-size: 16px
    line-height: 28px
    font-family: $Poppins
    color: $darkGrey900
.date__wrapper
    display: flex
    align-items: center
    font-size: 12px
    color: #666D82
    font-family: $Poppins
    margin-bottom: 4px
.imgWrapper
    display: flex
    justify-content: center
    align-items: center
    margin-left: 4px
    margin-right: 4px
.filterWrapper
    display: flex
    justify-content: space-between
    align-items: center
    padding-top: 12px
    padding-bottom: 12px
.filtersRight
    display: flex
.searchResults
    font-size: 16px
    line-height: 28px
    color: $darkGrey900
    font-family: $Poppins
.clearAll
    background: transparent
    outline: none
    margin-right: 16px
    border: none
    padding: 0

