@import '../../../../../../components/assets/styles/variables'
@import '../../../../../../components/assets/styles/mixin'
.outerWrapper
  position: relative
.container
  display: block
  border: 1px solid #F2F3F5
  border-radius: 24px
  width: 100%
  padding: 16px
  &_highlighted 
    border: 1.5px solid #2A1BA1
    box-shadow: 0px 4px 24px rgba(212, 209, 236, 0.3)
.top__title
  width: 100%
  margin-bottom: 12px
  span:first-letter
    text-transform: uppercase
.cursor__pointer
  cursor: pointer
.dropdown
  cursor: pointer
  width: 16px
  height: 16px
  &__rotate
    transform: rotate(180deg)
.about
  display: none
  &__show
    display: flex
    flex-direction: column
    margin-bottom: 16px
  &__title
    font-family: $PoppinsSemiBold
    font-weight: 600
    font-size: 14px
    line-height: 20px
    margin-bottom: 8px
    @include r(768)
      font-weight: 600 !important
      font-size: 14px !important
      line-height: 20px !important
  &__text
    font-weight: 400 !important
    font-size: 16px !important
    line-height: 28px !important
.bottom__side
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  @include r(970)
    padding-top: 16px
.price__quantity
  display: flex
  flex-direction: column
.price
  font-family: $PoppinsSemiBold !important
  font-weight: 600 !important
  font-size: 18px !important
  line-height: 30px !important
  margin-bottom: 2px !important
.quantity
  font-weight: 400 !important
  font-size: 12px !important
  line-height: 16px !important
  color: #808697 !important
.counter
  font-family: $Poppins
  border: 1.5px solid #666D82
  border-radius: 16px
  padding: 12px 0
  width: 140px
  height: 52px
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  &__num
    font-weight: 400 !important
    font-size: 16px !important
    line-height: 28px !important
    color: $darkgrey500 !important
    position: absolute
    top: calc(50% - 14px)
    left: calc(50% - 10px)
    width: 20px
    height: 28px
    text-align: center
  img
    cursor: pointer
    margin: 5px auto
.add__btn
  display: flex
  align-items: center
  justify-content: center
  color: $violet800
  padding: 14px 28px
  height: 52px
  width: 140px
  border: 1.5px solid #2A1BA1
  border-radius: 16px
  font-family: $PoppinsSemiBold
  font-weight: 600
  font-size: 16px
  line-height: 20px
  cursor: pointer
.name__logo 
  min-height: 194px
.logo
  width: 100%
  height: 180px
  object-fit: cover
  border-radius: 16px
  margin-bottom: 12px
.name
  @include r(768)
    font-weight: 400 !important
    font-size: 16px !important
    line-height: 28px !important
    margin-right: 16px
.plus
  margin-right: 6px
.add__btn
  display: flex
  align-items: center
  justify-content: center
  color: $violet800
  padding: 14px 8px
  height: 52px
  width: 170px
  border: 1.5px solid #2A1BA1
  border-radius: 16px
  font-family: $PoppinsSemiBold
  font-weight: 600
  font-size: 16px
  line-height: 20px
  cursor: pointer
  background: transparent
  &__active
    color: $white
    background-color: #2A1BA1
.plus
  margin-right: 6px
.calendarIcon
  margin-right: 6px
  position: relative
  top: -2px
.calendar
  top: 100% !important
  left: 150px !important
  @include r(1092)
    left: 100px !important
.overlayDate
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0, 5, 18, 0.3)
    z-index: 21
    overflow-y: auto 
    display: none  
    @include r(768)
        display: block 
        display: flex
        flex-direction: column